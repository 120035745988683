#missing-license .box-wrapper, #activate-license .box-wrapper {
  text-align: left;
  cursor: pointer;
  max-width: 200px;
  margin: 1em auto;
  padding-bottom: 2em;
}

#missing-license .box-wrapper input, #activate .box-wrapper input {
    display: inline;
    width: auto;
    margin-right: 0.5em;
}

#sign-in-wrapper .button {
    display: block;
    margin: 2em auto;
}

#sign-in-wrapper .button-secondary {
    font-size:14px;
    line-height: 20px;
    min-height: 32px;
    margin-top:2em;
    color: #777;
    background-color: #f2f2f2;
}

#activate .activate-form .box-wrapper {
  margin: 1em auto;
}

#activate .activate-form .box-wrapper label {
  margin-top:1em;
  padding: 0.5em;
  cursor: pointer;
  border: 1px transparent solid;
  border-radius: 4px;
  background-color: rgba(0,0,200,0.05);
}

#activate .activate-form input[type="radio"]:checked+label {
  border-color: #44a;
  background-color: rgba(0,0,200,0.2);
}

#activate .activate-form .box-wrapper label:hover {
  border-color:#ffffff;
  transition: all 0.15s ease-out;
  background-color: rgba(0,0,200,0.1);
}

#activationConfirmation {
    text-align: left;
}