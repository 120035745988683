@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');

#sign-in-wrapper {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: unset;
    color: #fff;
    background-color: rgba(0, 0, 30, 1);
    background-image: url(../../Assets/Images/authbackground.png);
    background-size: cover;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 22px;
}

#sign-in-header,
#sign-in-content,
#license-login-choice,
#register {
    max-width: 950px;
    margin: 0 auto auto auto;
    padding: 0 2em;
}

#sign-in-content,
#license-login-choice,
#register {
    flex-grow: 1;
}

#sign-in-top-bar {
    display: flex;
    flex-direction: row;
}

#sign-in-intro {
    text-align: left;
    width: 65%;
    font-size: 0.9em;
}
#sign-in {
    margin-left: 2em;
}

#mynumbers-logo {
    background-repeat: no-repeat;
    background-size: initial;
    background-position: bottom;
    width: 150px;
    height: 45px;
    padding-top: 2vh;
    margin: 0 auto 20px auto;
}

#sign-in-top-bar h2 {
    margin: 0;
    font-family: 'Francois One', sans-serif;
    font-size: 25px;
    font-weight: normal;
}

#sign-in-bottom {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
}

#sign-in-bottom > div {
    width: 32%;
    background-color: rgba(0, 0, 30, 0.5);
    box-sizing: border-box;
    padding: 8px 18px;
    border-radius: 8px;
    font-size: 0.9em;
}

#sign-in-bottom a:link {
    color: rgb(170, 170, 255);
}

#sign-in-bottom a:visited:hover,
#sign-in-bottom a:hover {
    color: rgb(200, 200, 255);
}

#sign-in-bottom a:visited {
    color: #b56adb;
}

#sign-in-intro ul,
#sign-in-bottom ul {
    font-size: 0.8em;
    padding-inline-start: 30px;
}

#sign-in-intro li,
#sign-in-bottom li {
    line-height: 21px;
    font-weight: bold;
    font-size: 14px;
    font-family: sans-serif;
}

#license-login-choice {
    max-width: 400px;
}

#sign-in-bottom h3 {
    font-family: 'Roboto', 'Helvetica', sans-serif;
    font-variant: all-petite-caps;
    font-size: 20.5px;
    border-bottom: 1px solid white;
    margin: 0 0 0.5em 0;
    line-height: 40px;
    font-weight: 400;
}

.input.bank-id-input {
    background-image: url(/src/Assets/Images/bankid.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 0px;
    max-width: 200px;
}

.sign-in-button {
    margin: 0px auto;
    display: block;
    width: 175px;
}

.bank-id-stand-alone-logo {
    background-image: url(/src/Assets/Images/bankid.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 106px;
    height: 70px;
    width: 106px;
    float: none;
    margin: 20px auto 0 auto;
    display: block;
}

.bank-id-device-change.link {
    width: 200px;
    line-height: 20px;
    margin-bottom: 25px;
}

.button {
    color: #fff;
    background-color: #6d5bd0;
    font-size: 18px;
    line-height: 26px;
    min-height: 40px;
    min-width: 160px;
    display: inline-block;
    font-weight: 400;
    margin: 0;
    border-radius: 3px;
    padding: 1px 15px 3px 15px;
    border-style: none;
    text-decoration: none;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 9px 8px;
    cursor: pointer;
}

.bank-id-button {
    background-image: url(/src/Assets/Images/bankid-white.svg);
    background-repeat: no-repeat;
    background-size: 114px 50px;
    background-position: calc(100% + 21px) 0px;
    height: 50px;
    line-height: 50px;
    width: 200px;
    margin: 12px auto;
}

.sign-in-heading {
    float: none;
    margin: 10px 0 35px 0;
    padding: 0;
    font-family: 'Francois One', sans-serif;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    color: #ffffff;
}

#sign-in-beta {
    display: none;
    font-family: 'Francois One', sans-serif;
    font-weight: 300;
    font-size: 18px;
    font-style: italic;
    margin: 0.4em 0 2em;
    text-align: center;
}

.signed-out-message {
    display: inline-block;
    float: none;
    width: 400px;
    min-height: 30px;
    line-height: 30px;
    margin: -20px auto 30px auto;
    text-align: center;
    padding: 15px;
    overflow: hidden;
    border: 1px #d0d4d7 solid;
    border-radius: 10px;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    letter-spacing: 0.6px;
}

.form-wrapper {
    width: 274px;
    min-height: 220px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 40px 40px 20px 40px;
    overflow: hidden;
    border-radius: 30px;
    box-shadow: 3px 3px 15px 0 rgb(0 0 0 / 20%);
}

.form-wrapper form {
    padding: 5px 0 0 0;
    width: 100%;
    margin: 0 0 20px 0;
}

.input-container {
    display: inline-block;
}

.form-wrapper form label {
    font-family: Lato, sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #160a25;
    display: inline-block;
    margin: 0 0 10px 0;
}

.form-wrapper form input,
#register form input {
    width: calc(100% - 14px);
    max-width: 250px;
    font-size: 16px;
    margin: 0 0 16px 0;
    padding: 11px 6px;
    border-radius: 5px;
    border: 1px #8b949e solid;
}

.form-wrapper .spinner-wrapper {
    float: none;
    margin: 0;
}

button.link {
    border: 0;
    background-color: unset;
    color: #6d5bd0;
    text-decoration: none;
    cursor: pointer;
}

button.link:hover {
    text-decoration: underline;
}

.form-wrapper form button:disabled {
    background-color: #f5f5f7;
    color: #cfcdd4;
    pointer-events: none;
}

.form-wrapper form button .mask {
    mask-image: url(/src/Assets/Images/sign-in.svg);
    mask-repeat: no-repeat;
    float: left;
    background-color: #ffffff;
    width: 24px;
    height: 22px;
    margin: 1px 12px 0 0;
}

.form-wrapper form button:disabled .mask {
    background-color: #cfcdd4;
}

.form-wrapper form button.bank-id-button:hover {
    background-color: #372d68;
}

.checkbox-wrapper {
    display: block;
    float: left;
    overflow: hidden;
    min-width: 150px;
}

.form-wrapper form .checkbox-wrapper input[type='checkbox'] {
    width: 20px;
    margin: 10px 10px 0 0;
    transform: scale(1.4);
}

.form-wrapper form .checkbox-wrapper p {
    margin: 5px 0;
    font-size: 15px;
    line-height: 20px;
    color: #999999;
}

.checkbox-container input:hover ~ .checkmark,
.checkbox-container input:checked ~ .checkmark {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}

.checkbox-container input:checked ~ .checkmark:after {
    border-color: #372d68;
}

.checkbox-container .checkbox-label {
    margin-left: 32px;
    font-size: 20px;
}

.forgot-password {
    color: #454545;
    font-size: 13px;
    line-height: 21px;
    margin-top: 28px;
}

.forgot-password-text {
    background-repeat: no-repeat;
    background-position: 100% 2px;
    padding-right: 28px;
    color: #6d5bd0;
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    cursor: pointer;
}

.to-be-refactored div,
.to-be-refactored input {
    opacity: 0;
}

.fixed-layer {
    position: fixed;
    display: block;
    float: left;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-repeat: no-repeat;
    background-size: 1210px 713px;
    background-position: 3px 0px;
    z-index: 1000;
    display: none;
}

.sign-in-spinner-cover {
    width: 310px;
    height: 250px;
    margin-left: -20px;
    margin-top: -20px;
    padding-top: 0px;
    text-align: center;
    float: left;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    z-index: 300;
}

.sign-in-spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    margin-left: -15px;
    margin-top: 90px;
}

.sign-in-spinner:after {
    content: ' ';
    display: block;
    width: 30px;
    height: 30px;
    margin: 0px;
    border-radius: 50%;
    border: 2px solid #007cba;
    border-color: #007cba transparent #007cba transparent;
    animation: spinner 1.2s linear infinite;
}

.footer.sign-in-footer {
    position: unset;
    top: unset;
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: #999999;
    letter-spacing: 1px;
    margin-left: 0;
    display: block;
    flex-basis: 20px;
}

.footer.sign-in-footer a {
    color: #999999;
    margin: 0 10px 0 0;
    text-underline-offset: 1px;
}

.moms-text {
    text-align: left;
}

#sign-in-content .message {
    font-family: lato, sans-serif;
    line-height: 25px;
    background-repeat: no-repeat;
    background-position: 4px 7px;
    display: inline-block;
    margin: 0 40px 10px 40px;
    color: #ffffff;
    min-width: 50%;
    background-color: rgba(0, 0, 30, 0.5);
    box-sizing: border-box;
    padding: 20px 40px 30px 40px;
    border-radius: 8px;
    font-size: 1em;
}

#sign-in-content .message a {
    color: #ffffff;
}

#sign-in-content .message > ul {
    text-align: center;
    display: inline-block;
}

#sign-in-content .message li {
    text-align: left;
}

#sign-in-content .message .link {
    text-decoration: underline;
}

#sign-in-content .error-message {
    color: #da546c;
    background-color: rgba(218, 84, 108, 0.12);
    display: block;
}

#sign-in-content .error-message a {
    display: block;
}

#sign-in-wrapper .error-wrapper {
    color: #000000;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 750px) {
    #sign-in-top-bar,
    #sign-in-bottom {
        flex-direction: column;
    }

    #sign-in-intro {
        width: unset;
    }

    #sign-in-bottom > div {
        width: unset;
        margin-bottom: 1em;
    }
}
