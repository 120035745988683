@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');

html {
    height: 100%;
}

body {
    background-color: rgba(45, 55, 104, 0.05);
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}

body > div,
body > div > div,
body > div > div > div {
    height: 100%;
}

body > div > div > div {
    max-width: 2000px;
    padding: 0;
    margin: 0;
}

.content-wrapper {
    margin: 0;
    padding: 0 0 50px 0;
    float: left;
    width: 100%;
    font-family: 'Lato', sans-serif;
    background-color: #ffffff;
    min-height: 650px;
}

.mockup-overlay {
    display: block;
    display: none;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.official-data-disclaimer {
    color: #aaaaaa;
    font-size: 13px;
    display: block;
    margin: 20px 0 50px 79px;
    float: left;
    clear: left;
}

.footer {
    position: relative;
    bottom: 24px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: #999999;
    letter-spacing: 1px;
    background-color: #fbfbff;
    height: 17px;
    padding: 10px 0;
    display: inline-block;
    border-top: 1px #efefef solid;
}

.footer a {
    color: #999999;
    margin: 0 10px 0 0;
    text-underline-offset: 1px;
}

.footer a:hover {
    color: #372d68;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

::-webkit-scrollbar {
    background: #eeebff;
    overflow: visible;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #6d5bd0;
    border-radius: 4px;
    border: none;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #372d68;
    border-radius: 4px;
}

@media screen and (max-width: 1350px) {
    .official-data-disclaimer {
        margin-left: 40px;
    }
}

@media screen and (min-width: 2000px) {
    body > div > div > div {
        margin-left: calc(50% - 1000px);
    }
}
