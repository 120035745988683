.snackbar-content {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    background-color: #000;
    color: #fff;
    padding: 10px;
    border-radius: 6px;
    opacity: 0.85;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.snackbar-content.error {
    background-color: #300;
}
.snackbar-content.error .snackbar-message svg {
    color: #fbb;
}

.snackbar-content.success {
    background-color: #020;
}
.snackbar-content.success .snackbar-message svg {
    color: #cfc;
}

.snackbar-content.warning {
    background-color: #310;
}
.snackbar-content.warning .snackbar-message svg {
    color: #fa4;
}

.snackbar-content .snackbar-message {
    display: flex;
    gap: 16px;
    flex: 1;
    align-items: center;
}

.snackbar-content .snackbar-dismiss {
    display: flex;
    height: fit-content;
    align-items: center;
    cursor: pointer;
}
