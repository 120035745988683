@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.auto-tooltip {
    position: relative;
}

.auto-tooltip .tooltip-message {
    background: #fff;
    border: 1px solid #ddd;
    bottom: 75%;
    left: 0;
    opacity: 0;
    padding: 5px 10px;
    position: absolute;
    visibility: hidden;
    width: 100%;

    color: #333;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.auto-tooltip .tooltip-message::after {
    content: '';
    background: #fff;
    border: 0;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    border-left-width: 0px;
    border-left-style: none;
    border-left-color: currentcolor;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    bottom: -8px;
    height: 15px;
    left: 15px;
    position: absolute;
    width: 15px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
}
.auto-tooltip:hover .tooltip-message {
    bottom: 100%;
    opacity: 1;
    visibility: visible;
}

.budget-content {
    margin: 0 78px;
    clear: left;
    padding-top: 10px;
}

.budget-create-row {
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
    justify-content: space-between;
}
.budget-create-row > span {
    min-width: 200px;
    word-wrap: normal;
}
.budget-create-row .input {
    width: 100px;
}
.budget-create-row .dropdown {
    min-height: 30px;
}
.budget-create-row .dropdown .dropdowns-wrapper,
.budget-create-row .dropdown .dropdowns-wrapper .secondary-legends {
    margin: 0;
}
.budget-create-row .dropdown .dropdowns-wrapper .secondary-legends {
    float: left;
}

.budget-table-row {
    display: flex;
    align-items: center;
}
.budget-table-row .start-edit-months {
    margin: 0 auto;
}

.budget-table {
    --expanded-border-radius: 20px;
    --expanded-margin-top: 2px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    max-width: 1350px;
}

.budget-table th {
    text-align: right;
}

.budget-table th.left-align {
    text-align: left;
}

.budget-table .money-column,
.budget-table .money-column input {
    text-align: right;
    font-size: 13px;
    font-family: 'Lato', sans-serif;
}

.budget-table input,
.budget-table button {
    padding-top: 0;
    padding-bottom: 0;
}
.budget-table input:disabled {
    background-color: lightgray;
}

.budget-table .row-name span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
}

.budget-table .row-name button {
    white-space: nowrap;
    color: #fbfbff;
    font-weight: bolder;
}

.budget-table .detail-column {
    font-size: 13px;
    font-style: italic;
    text-align: right;
    padding-right: 0.5em;
}

.budget-table .expand-cell {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.budget-table .expand-cell button {
    background-color: #6d5bd0;
    height: 22px;
    width: 45px;
}

.budget-table .percent-row td {
    font-size: 11px;
}

.budget-table tr.shaded td.shaded {
    background-color: #f2f1fa;
}
.budget-table tr.shaded.top-row td.shaded {
    padding-top: calc(var(--expanded-border-radius) / 4);
    border-top: var(--expanded-margin-top) solid white;
}
.budget-table tr.shaded.bottom-row td.shaded {
    padding-bottom: calc(var(--expanded-border-radius) / 4);
}
.budget-table td.right-end {
    padding-right: calc(var(--expanded-border-radius) / 2);
}
.budget-table td.left-start {
    padding-left: calc(var(--expanded-border-radius) / 2);
}
.budget-table tr.shaded.top-row td.left-start {
    border-top-left-radius: var(--expanded-border-radius);
}
.budget-table tr.shaded.top-row td.right-end {
    border-top-right-radius: var(--expanded-border-radius);
}
.budget-table tr.shaded.bottom-row td.left-start {
    border-bottom-left-radius: var(--expanded-border-radius);
}
.budget-table tr.shaded.bottom-row td.right-end {
    border-bottom-right-radius: var(--expanded-border-radius);
}

.budget-table td.green-cell {
    background-color: #88dad6;
}

.budget-table tr.bold {
    font-weight: bold;
}

.budget-table tr.line-above td {
    border-top: 1px solid black;
}

.budget-table tr.spacer {
    height: 10px;
}

.budget-table .fa-button {
    font-size: 10px;
    background-color: #007cba;
    padding: 3px;
    margin-left: 1px;
    margin-right: 1px;
}

.budget-list {
    max-width: 800px;
}

.budget-list-title-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    font-size: 0.8em;
    margin-bottom: 0.4em;
}
.budget-list-title-row .budget-period {
    width: 420px;
}

.budget-list-item {
    display: flex;
    align-items: baseline;
}

.budget-name {
    flex-grow: 1;
}
.budget-list-item .budget-period {
    font-size: 0.7em;
    margin-right: 2em;
}

.create-button-row {
    margin-top: 2em;
}

.create-budget {
    color: white;
    background-color: #6d5bd0;
    padding: 0.4em;
    padding-left: 0.2em;
    text-decoration: none;
}
.fa-button.create-budget:before {
    vertical-align: bottom;
}

.budget-title-row {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 0.3em;
}
.budget-title-row #controls {
    align-items: flex-start;
    display: flex;
    gap: 20px;
    position: relative;
}
.budget-title-row button {
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 37px;
    margin: 0 4px 0 0;
    padding: 8px 15px 8px 15px;
    border-style: none;
    border: 1px solid white;
    border-radius: 5px;
    vertical-align: middle;
}

.budget-title-row button.disappear {
    color: #1b7f7b;
    background-color: white;
}
.budget-title-row button.disappear:before {
    background-color: #1b7f7b;
}

.budget-title-row button.save {
    background-color: #6d5bd0;
    border-color: #6d5bd0;
    color: #ffffff;
}
.budget-title-row button.save:hover {
    background-color: #372d68;
    border-color: #372d68;
}
.budget-title-row button.save:disabled {
    background-color: #efefef;
    color: #372d68;
    border-color: #cbc6ec;
}

.budget-title-row button.help {
    color: #6d5bd0;
    background-color: white;
    padding-left: 10px;
}
.budget-title-row button.help:before {
    content: '';
    background-color: #6d5bd0;
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-image: url(/src/Assets/Images/help.svg);
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    mask-size: 1.2em 1.2em;
    margin-right: 5px;
    vertical-align: bottom;
}
.budget-title-row button.help:hover {
    border-color: #6d5bd0;
}

.budget-title-row #controls .title {
    color: #6d5bd0;
    display: block;
    font-size: 0.8em;
    margin: 2px 0 5px;
    text-transform: uppercase;
}
.budget-title-row #budget-name h3 {
    margin-top: 0;
    border: 1px solid #cbc6ec;
    border-radius: 2px;
    padding: 4px;
    min-width: 13.2em;
    display: inline-block;
    cursor: text;
    position: relative;
}

.budget-title-row #budget-name h3::after {
    content: '';
    mask-image: url(/src/Assets/Images/edit.svg);
    mask-position: center center;
    background-color: #cbc6ec;
    vertical-align: middle;
    mask-repeat: no-repeat;
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    margin-right: 0;
    position: absolute;
    right: 2px;
    top: 7px;
}

.budget-title-row #budget-name h3:hover {
    border: 1px solid;
    border-color: #6d5bd0;
}

.budget-title-row #budget-name h3:hover::after {
    background-color: #372d68;
}

.budget-title-row #budget-name input.input {
    font-family: 'Lato', sans-serif;
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0 22px 0;
}

#budget-period button {
    background-color: #6d5bd0;
    color: #fbfbff;
    margin-left: 1em;
    cursor: pointer;
    font-size: 0.9em;
}

#budget-period button.disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

#budget-period-picker {
    min-height: 200px;
}

.budget-action-list {
    display: flex;
    justify-content: flex-end;
    max-width: 1350px;
    gap: 0.5em;
}

button.budget-action-button {
    height: 22px;
    background-color: #6d5bd0;
    color: #fbfbff;
}

.budget-list-item button {
    height: 2em;
    text-align: left;
    background-color: white;
    padding: 1px 7px 3px 7px;
    margin: 0.1em;
    color: #6d5bd0;
    cursor: pointer;
}
.budget-list-item button.toggle {
    width: 120px;
}
.budget-list-item button:before {
    background-color: #6d5bd0;
}
.budget-list-item button.trash:before {
    background-color: #da546c;
}
.budget-list-item button:disabled {
    background-color: #efefef;
}
.budget-list-item button:disabled:before {
    background-color: #372d68;
}
.budget-list-item button:disabled {
    color: #372d68;
}

button.budget-action-button:hover {
    background-color: #372d68;
}

button.budget-action-button:disabled {
    background-color: #cbc6ec;
}
.budget-content .dropdowns-wrapper .secondary-legends {
    max-width: 180px;
}
.budget-content .dropdowns-wrapper .secondary-legends .secondary-legends-button {
    width: 120px;
}
.budget-content .dropdowns-wrapper .secondary-legends:hover .secondary-legends-button {
    position: relative;
    display: block;
    margin-left: 30px;
    left: auto;
}

.no-icon,
.fa-button,
.fa-button-after,
button.budget-action-button {
    border-radius: 3px;
    border-style: none;
}
.fa-button:before,
.fa-button-after:after {
    content: '';
    mask-position: center center;
    background-color: #fbfbff;
    vertical-align: middle;
    mask-repeat: no-repeat;
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin: 2px;
    margin-right: 5px;
    margin-left: 5px;
}

.fa-button.edit:before,
.fa-button-after.edit:after {
    mask-image: url(/src/Assets/Images/edit.svg);
}
.fa-button.toggle-on:before,
.fa-button-after.toggle-on:after {
    width: 1.8em;
    height: 1.4em;
    mask-image: url(/src/Assets/Images/toggle-on.svg);
}
.fa-button.toggle-off:before,
.fa-button-after.toggle-off:after {
    width: 1.8em;
    height: 1.4em;
    mask-image: url(/src/Assets/Images/toggle-off.svg);
}
.fa-button.check:before,
.fa-button-after.check:after {
    mask-image: url(/src/Assets/Images/check.svg);
}
.fa-button.chart-bar:before,
.fa-button-after.chart-bar:after {
    mask-image: url(/src/Assets/Images/chart-bar-solid.svg);
}
.fa-button.chart-column:before,
.fa-button-after.chart-column:after {
    mask-image: url(/src/Assets/Images/chart-column-solid.svg);
}
.fa-button.rotate-left:before,
.fa-button-after.rotate-left:after {
    mask-image: url(/src/Assets/Images/rotate-left-solid.svg);
}
.fa-button.rotate-right:before,
.fa-button-after.rotate-right:after {
    mask-image: url(/src/Assets/Images/rotate-right-solid.svg);
}
.fa-button.fold:before,
.fa-button-after.fold:after {
    mask-image: url(/src/Assets/Images/fold-icon.svg);
}
.fa-button.unfold:before,
.fa-button-after.unfold:after {
    mask-image: url(/src/Assets/Images/unfold-icon.svg);
}
.fa-button.trash:before,
.fa-button-after.trash:after {
    mask-image: url(/src/Assets/Images/trash-solid.svg);
}

.top-bar {
    width: 100%;
    float: left;
    height: 63px;
    background-color: #3a306e;
    color: #ffffff;
    margin: 0;
    padding: 0;
}

.top-bar .logo {
    position: relative;
    top: 16px;
    left: 41px;
    width: 100px;
    height: 35px;
    background-repeat: no-repeat;
    float: left;
    cursor: pointer;
}

.top-bar-logout {
    min-width: 60px;
    margin: 23px 33px 0 33px;
    height: 24px;
    float: right;
    text-align: right;
}

.top-bar-logout span.license-switch {
    background-image: url(/src/Assets/Images/top-bar-user-avatar.svg);
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    font-family: Lato, sans-serif;
    color: #ffffff;
    background-repeat: no-repeat;
    background-position: 0 2px;
    margin: 0 20px 0 22px;
    padding: 3px 0 0 21px;
    text-indent: 20px;
}

.top-bar .settings-link {
    padding: 3px 0 0 0;
    text-align: left;
    color: #372d68;
    text-decoration: none;
}

.top-bar .settings-icon {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 18px;
    top: 2px;
    margin-right: 5px;
    border-radius: 0;
    background-color: #372d68;
    mask-size: 18px 18px;
    mask-repeat: no-repeat;
    mask-image: url(/src/Assets/Images/user-gear-solid.svg);
}

.top-bar-logout span.license-switch {
    display: inline-block;
    height: 32px;
    line-height: 30px;
    background-position: 6px 7px;
    border: 1px transparent solid;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    padding: 0;
    margin: -8px 20px 0 22px;
    text-indent: 26px;
}

.top-bar-logout span.license-switch .mask {
    display: inline-block;
    mask-image: url(/src/Assets/Images/chevron-down.svg);
    background-color: #cfcdd4;
    mask-repeat: no-repeat;
    width: 24px;
    height: 15px;
    margin: -3px 0 0 5px;
    line-height: normal;
    position: relative;
    top: 3px;
}

.top-bar-logout span.logout {
    color: #ffffff;
    font-style: normal;
    line-height: 30px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    width: 65px;
    height: 32px;
    margin: 0;
    margin-top: -6px;
    padding: 0 10px 0 13px;
    float: right;
    text-align: left;
    letter-spacing: 0.3px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
}

.top-bar-logout span.logout:hover,
.top-bar-logout span.license-switch:hover {
    border-color: #ffffff;
    transition: all 0.15s ease-out;
}

.top-bar-logout .user-menu {
    z-index: 1000;
    position: relative;
    background: #fff;
    width: 400px;
    padding: 2em;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.15);
    top: 15px;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    text-align: left;
}

.top-bar-logout .license-switch-choice {
    text-align: center;
}

.content-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    z-index: 100;
    text-align: center;
    margin: 0;
    padding: 0;
}

.compare-overlay .spinner-wrapper,
.content-overlay .spinner-wrapper {
    top: 50%;
    position: absolute;
    margin-top: -200px;
    padding-left: 10px;
}

.content-overlay .error-wrapper {
    top: 50%;
    left: 50%;
    position: absolute;
    padding: 45px 30px;
    margin: -200px 0 0 -330px;
    height: 140px;
    width: 600px;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    background-color: #ffffff;
    border: 1px #cdcdcd solid;
    border-radius: 8px;
}

.content-overlay .error-wrapper button {
    background-color: #007cba;
    color: #ffffff;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    float: none;
    margin: -11px 0 16px 15px;
    border-radius: 5px;
    padding: 8px 14px 6px 14px;
    width: unset;
    border-style: none;
    cursor: pointer;
    position: relative;
}

.company-heading,
.content-heading {
    margin: 0;
    padding: 0 0 0 80px;
    float: left;
    width: calc(100% - 80px);
    background-color: #fbfbff;
}

.no-float {
    clear: both;
    float: none;
}

.content-heading .breadcrumbs {
    color: #160a25;
    font-size: 14px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    margin: 15px 0 0 0;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    position: relative;
}

.content-heading .breadcrumbs span + span:before {
    content: '/';
    position: relative;
    left: -10px;
}

.content-heading .breadcrumbs a {
    color: #6d5bd0;
    text-decoration: none;
}

.content-heading .breadcrumbs a:hover {
    color: #372d68;
    text-decoration: underline;
    transition: all 0.15s ease-out;
}

.bottom-buttons-and-info .official-data-disclaimer {
    margin-left: 75px;
}

.bottom-buttons-and-info .help,
.company-heading .help,
.content-heading .help {
    color: #6d5bd0;
    font-size: 16px;
    line-height: 36px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    width: 76px;
    height: 36px;
    margin: 20px 78px 0 0;
    padding: 0 2px 0 10px;
    float: right;
    text-align: left;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
    z-index: 150;
    position: relative;
}

.company-heading .help.help-above {
    margin-top: -44px;
}

.bottom-buttons-and-info .help {
    float: left;
    margin-left: clamp(900px, calc(100% - 180px), 1155px);
    margin-top: -53px;
    position: absolute;
}

.bottom-buttons-and-info .help .mask,
.company-heading .help .mask,
.content-heading .help .mask {
    mask-image: url(/src/Assets/Images/help.svg);
    background-color: #6d5bd0;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    float: left;
    width: 20px;
    height: 20px;
    -webkit-mask-size: 20px 20px;
    mask-size: 20px 20px;
    margin: 9px 8px 0 0;
    position: relative;
    top: -2px;
}

.bottom-buttons-and-info .help:hover,
.company-heading .help:hover,
.content-heading .help:hover {
    border-color: #372d68;
    color: #372d68;
    transition: all 0.15s ease-out;
}

.bottom-buttons-and-info .help:hover .mask,
.company-heading .help:hover .mask,
.content-heading .help:hover .mask {
    background-color: #372d68;
}

.company-heading h1,
.content-heading h1 {
    float: left;
    clear: left;
    margin: 10px 0 6px 0;
    padding: 0;
    font-family: 'Francois One', sans-serif;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    color: #000000;
}

.content-heading h1 span {
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin-left: 18px;
}

.info-boxes {
    width: 100%;
    float: left;
    clear: left;
}

.company-info-visibility-toggle {
    float: left;
    margin: 58px 78px 0 -33px;
    mask-image: url(/src/Assets/Images/fold-company-info-icon.svg);
    mask-repeat: no-repeat;
    background-size: 100%;
    width: 14px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    background-color: #cbc6ec;
}

.company-info-visibility-toggle.folded {
    mask-image: url(/src/Assets/Images/unfold-company-info-icon.svg);
}

.company-info-visibility-toggle:hover {
    background-color: #372d68;
}

.info-boxes .company-info-box {
    width: calc(40% - 50px);
    float: left;
    padding: 5px 20px 0 0;
    min-height: 120px;
}

.info-boxes .company-info-box:nth-of-type(2) {
    width: calc(50% - 50px);
}

.info-boxes .company-info-box div {
    font-size: 15px;
    line-height: 25px;
}

.info-boxes .company-info-box div.has-info-icon {
    background-size: 15px 15px;
    background-repeat: no-repeat;
    text-indent: 26px;
    background-position: 0 2px;
}

.info-boxes .company-info-box div.has-info-icon.employees {
    background-image: url(/src/Assets/Images/employees.svg);
}

.info-boxes .company-info-box div.has-info-icon.city {
    background-image: url(/src/Assets/Images/city.svg);
}

.info-boxes.slim {
    clear: unset;
    width: 400px;
    margin: 20px 0 0 20px;
}

.info-boxes.slim .company-info-box {
    width: 400px;
    min-height: 20px;
}

.content-menu {
    margin: 10px 0 0 0;
    float: left;
    clear: left;
}

.content-menu ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
}

.content-menu ul li {
    height: 36px;
    font-family: Lato, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.content-menu ul a {
    padding: 14px 16px 12px 16px;
    color: #6d5bd0;
    text-decoration: none;
    border-bottom: 4px transparent solid;
    border-radius: 6px 6px 0 0;
    width: 100%;
    height: 100%;
    letter-spacing: 0.2px;
    margin: 0 5px 0 0;
    min-width: 180px;
}

.content-menu ul a:hover,
.content-menu ul a.active {
    background-color: #eeebff;
    border-bottom-color: #6d5bd0;
    transition: all 0.15s ease-out;
}

.content-menu .alert-bubble {
    position: relative;
    float: right;
    margin-top: 0px;
    right: -4px;
    color: #ffffff;
    background-color: #da546c;
    border-radius: 20px;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    min-width: 14px;
    height: 22px;
    padding: 0 4px;
    text-align: center;
}

.row-based-table-wrapper {
    padding: 0 0 0 80px;
}

.row-based-table-wrapper-heading {
    margin: 0 0 0 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-family: Lato, sans-serif;
    float: left;
    width: 100%;
    letter-spacing: 0.1px;
    cursor: pointer;
}

.row-based-table {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    float: left;
    width: 100%;
    font-size: 13px;
    margin-bottom: 50px;
}

.row-based-table-head {
    width: 100%;
    font-size: 15px;
    line-height: 22px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    float: left;
    display: flex;
    height: 45px;
    background-color: #fbfbff;
    border-bottom: 1px #cbc6ec solid;
}

.row-based-table-head:empty {
    display: none;
}

.row-based-table-column {
    width: 100%;
    font-size: 12px;
    float: left;
    background-color: #ffffff;
    display: flex;
}

.row-based-table-column:nth-of-type(1) {
    padding-left: 20px;
}

.row-based-table-head > div:hover,
.row-based-table-head > div.active {
    background-color: #eeebff;
    transition: all 0.15s ease-out;
}

.row-based-table-head > div:hover .row-based-table-column-sort,
.row-based-table-head > div.active .row-based-table-column-sort {
    background-color: #372d68;
}

.row-based-table-head span {
    float: left;
}

.row-based-table-column-sort {
    float: left;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    mask-size: 18px 18px;
    mask-repeat: no-repeat;
    background-color: #6d5bd0;
}

.row-based-table-column-sort:hover {
    background-color: #372d68;
}

.row-based-table-head .right span {
    float: none;
}

.row-based-table-head .right .row-based-table-column-sort {
    float: right;
}

.row-based-table-head .row-based-table-column-sort.number {
    mask-image: url(/src/Assets/Images/sort-number.svg);
}

.row-based-table-head .reversed .row-based-table-column-sort.number {
    mask-image: url(/src/Assets/Images/sort-number-reversed.svg);
}

.row-based-table-head .row-based-table-column-sort.a-z {
    mask-image: url(/src/Assets/Images/sort-a-z.svg);
}

.row-based-table-head .reversed .row-based-table-column-sort.a-z {
    mask-image: url(/src/Assets/Images/sort-a-z-reversed.svg);
}

.row-based-table-row {
    font-family: Lato, sans-serif;
    font-size: 13px;
    line-height: 22px;
    font-weight: 400;
    width: 100%;
    min-height: 32px;
    float: left;
    display: flex;
    border-bottom: 1px #f2f1fa solid;
}

.row-based-table-row:hover {
    background-color: #fbfbff;
    transition: all 0.15s ease-out;
}

.row-based-table-row:empty {
    display: none;
}

.column-based-table .budget-selector {
    background-color: #eeebff;
    width: 188px;
    height: 32px;
    border-radius: 0px;
    z-index: 20;
    position: relative;
}

.column-based-table .budget-selector::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    position: absolute;
    transform: rotate(45deg);
    right: 15px;
    top: 10px;
    border: 1px #6d5bd0 solid;
    border-style: none solid solid none;
}

.column-based-table .budget-selector.is-visible {
    display: block;
}

.column-based-table .budget-selector:hover {
    border-radius: 3px;
}

.column-based-table .budget-selector:hover::after {
    display: none;
}

.column-based-table .budget-selector:hover .label {
    display: none;
}

.column-based-table .budget-selector:hover ul li {
    display: list-item;
}

.column-based-table .budget-selector ul li.selected,
.column-based-table .budget-selector:hover ul li {
    display: list-item;
}

.column-based-table .budget-selector:hover .label {
    margin-bottom: 5px;
    text-align: left;
    display: block;
}

.column-based-table .budget-selector:hover ul {
    background-color: #eeebff;
}

.column-based-table .budget-selector .label {
    display: none;
}

.column-based-table .budget-selector ul {
    z-index: 20;
    display: block;
    margin: 0;
    padding: 5px 10px;
}

.column-based-table .budget-selector ul li {
    min-height: 24px;
    list-style: none;
    text-align: left;
    text-indent: 0;
    display: none;
    line-height: 25px;
}

.column-based-table .budget-selector ul li a {
    margin-left: 0;
    position: static;
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.column-based-table .budget-selector ul li a:hover {
    max-width: unset;
}

.column-based-table .budget-selector ul li.selected a {
    margin-left: 0;
}

.column-based-table .status-neutral,
.compare-wrapper .status-neutral,
.company-info-box .status-neutral,
.row-based-table-row .status-neutral {
    background-image: url(/src/Assets/Images/status-neutral.svg);
    font-size: 15px;
    padding: 1px 4px 2px 14px;
    border-radius: 6px;
    color: #6d5bd0;
    background-color: rgba(109, 91, 208, 0.1);
    background-repeat: no-repeat;
    background-position: 4px 9px;
}

.column-based-table .status-not-ok,
.compare-wrapper .status-not-ok,
.company-info-box .status-not-ok,
.row-based-table-row .status-not-ok {
    background-image: url(/src/Assets/Images/status-not-ok.svg);
    font-size: 13px;
    padding: 1px 6px 2px 16px;
    border-radius: 6px;
    color: #da546c;
    background-size: 10px 10px;
    background-color: rgba(218, 84, 108, 0.12);
    background-repeat: no-repeat;
    background-position: 4px 4px;
}

.charts-wrapper .status-ok,
.column-based-table .status-ok,
.compare-wrapper .status-ok,
.company-info-box .status-ok,
.row-based-table-row .status-ok {
    background-image: url(/src/Assets/Images/status-ok.svg);
    font-size: 13px;
    padding: 1px 6px 2px 17px;
    border-radius: 6px;
    color: #1b7f7b;
    background-color: rgba(39, 182, 176, 0.12);
    background-repeat: no-repeat;
    background-position: 4px 5px;
    background-size: 10px 10px;
}

.charts-wrapper .info-boxes .chart-box.status-ok {
    background-image: none;
    display: inline-block;
    float: none;
    margin: 10px 0 0 20px;
    width: calc(100% - 62px);
    text-align: center;
    max-width: unset;
}

.row-based-table-row .status-warning,
.column-based-table .status-warning {
    color: #46371a;
    background-color: rgba(240, 230, 140, 0.4);
    border-radius: 6px;
    font-size: 15px;
    padding: 1px 4px 2px 16px;
}

.compare-wrapper .status-not-ok {
    display: inline-block;
    background-position: 7px 11px;
    padding-left: 21px;
    margin: 5px 40px;
}

.no-buttons .legends-button {
    display: none;
}

.company-menu {
    margin: 30px 0 0 0;
    float: left;
    clear: left;
}

.company-menu ul {
    list-style: none;
    width: 890px;
    margin: 0;
    float: left;
    padding: 0;
}

.company-menu ul li {
    height: 36px;
    font-family: Lato, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.company-menu ul a {
    padding: 14px 16px 12px 16px;
    color: #6d5bd0;
    text-decoration: none;
    border-bottom: 4px transparent solid;
    border-radius: 6px 6px 0 0;
    width: 100%;
    height: 100%;
    letter-spacing: 0.2px;
    margin: 0 5px 0 0;
    min-width: 180px;
}

.company-menu ul a:hover,
.company-menu ul a.active {
    background-color: #eeebff;
    border-bottom-color: #6d5bd0;
    transition: all 0.15s ease-out;
}

.company-heading {
    background-color: unset;
    height: 0;
}

.company-heading.company-waterfall-heading {
    height: unset;
}

.company-heading .help {
    margin-top: 20px;
}

.company-heading h1 {
    font-size: 26px;
    line-height: 37px;
    margin-top: 38px;
}

.row-based-table-head > div:nth-of-type(1),
.row-based-table-row > div:nth-of-type(1) {
    padding-left: 36px;
}

.row-based-table-head div.small,
.row-based-table-row div.small {
    max-width: 150px;
}

.row-based-table-head div.medium,
.row-based-table-row div.medium {
    max-width: 225px;
    text-overflow: ellipsis;
}

.row-based-table-head div.large,
.row-based-table-row div.large {
    max-width: 430px;
}

.row-based-table-head div.right,
.row-based-table-row div.right {
    text-align: right;
}

.row-based-table-head div.right a,
.row-based-table-row div.right a {
    float: right;
}

.row-based-table-head > div,
.row-based-table-row > div {
    box-sizing: border-box;
    flex: 1 1;
    padding: 6px 20px 6px 10px;
    overflow: hidden;
    list-style: none;
    min-height: 22px;
    line-height: 20px;
}

.row-based-table-row > div.hide-overflow {
    white-space: nowrap;
}

.row-based-table-row > div .tooltip {
    position: absolute;
    margin-top: 5px;
    margin-left: 120px;
    background-color: #f2f1fa;
    color: #000000;
    padding: 10px;
    border-radius: 5px;
    display: none;
    max-width: 300px;
    white-space: normal;
    border: 1px #cbc6ec solid;
}

.row-based-table-row > div:hover .tooltip {
    display: block;
}

.row-based-table-head > div {
    padding: 10px 20px 6px 10px;
}

.row-based-table-head > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 45px;
    line-height: 30px;
    user-select: none;
}

.row-based-table a {
    color: #6d5bd0;
    text-decoration: none;
    display: block;
    margin: 0;
    padding: 0;
    float: left;
}

.row-based-table a:hover {
    color: #372d68;
    text-decoration: underline;
    transition: all 0.15s ease-out;
}

.checkbox-col {
    padding-left: 10px;
    max-width: 46px;
}

.row-based-table-head > div.checkbox-col:nth-of-type(1),
.row-based-table-row > div.checkbox-col:nth-of-type(1) {
    padding-left: 10px;
}

.checkbox-col.checkbox-disabled {
    user-select: none;
    pointer-events: none;
    cursor: default;
}
.checkbox-col.checkbox-disabled .checkmark {
    border: 2px #efefef solid;
    background-color: #efefef;
}

/* Customize the label (the container) */
.checkbox-container {
    display: block;
    float: left;
    position: relative;
    padding-left: 0px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    border: 2px #cbc6ec solid;
    border-radius: 2px;
    background-color: #ffffff;
    margin: 0px 0 0 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #372d68;
    border: 2px #372d68 solid;
    transition: all 0.15s ease-out;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #6d5bd0;
    border-color: #6d5bd0;
}

.checkbox-container input:checked:hover ~ .checkmark {
    background-color: #372d68;
    border-color: #372d68;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container input ~ .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.row-based-table-actions {
    margin: -5px 0 10px 0;
}

.row-based-table-filter-wrapper {
    display: inline-block;
    width: 350px;
    text-align: right;
    position: absolute;
    margin-top: -50px;
    margin-left: calc(100% - 464px);
}

.row-based-table-filter-wrapper label {
    float: left;
    font-family: Lato, sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #160a25;
    position: relative;
    top: -3px;
}

.row-based-table-filter-wrapper input {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8906 14.6531L12.0969 10.8594C12.025 10.7875 11.9313 10.75 11.8313 10.75H11.4187C12.4031 9.60938 13 8.125 13 6.5C13 2.90937 10.0906 0 6.5 0C2.90937 0 0 2.90937 0 6.5C0 10.0906 2.90937 13 6.5 13C8.125 13 9.60938 12.4031 10.75 11.4187V11.8313C10.75 11.9313 10.7906 12.025 10.8594 12.0969L14.6531 15.8906C14.8 16.0375 15.0375 16.0375 15.1844 15.8906L15.8906 15.1844C16.0375 15.0375 16.0375 14.8 15.8906 14.6531ZM6.5 11.5C3.7375 11.5 1.5 9.2625 1.5 6.5C1.5 3.7375 3.7375 1.5 6.5 1.5C9.2625 1.5 11.5 3.7375 11.5 6.5C11.5 9.2625 9.2625 11.5 6.5 11.5Z' fill='%236D5BD0'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 282px 8px;
    clear: left;
    float: right;
    margin: 0px 0 16px 0;
    padding: 3px 35px 3px 6px;
    border-radius: 3px;
    border: 1px #6d5bd0 solid;
    line-height: 28px;
    width: 268px;
    font-size: 14px;
    outline-width: 0;
    text-indent: 5px;
}

.row-based-table-filter-wrapper input:focus,
.row-based-table-filter-wrapper input:hover {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8906 14.6531L12.0969 10.8594C12.025 10.7875 11.9313 10.75 11.8313 10.75H11.4187C12.4031 9.60938 13 8.125 13 6.5C13 2.90937 10.0906 0 6.5 0C2.90937 0 0 2.90937 0 6.5C0 10.0906 2.90937 13 6.5 13C8.125 13 9.60938 12.4031 10.75 11.4187V11.8313C10.75 11.9313 10.7906 12.025 10.8594 12.0969L14.6531 15.8906C14.8 16.0375 15.0375 16.0375 15.1844 15.8906L15.8906 15.1844C16.0375 15.0375 16.0375 14.8 15.8906 14.6531ZM6.5 11.5C3.7375 11.5 1.5 9.2625 1.5 6.5C1.5 3.7375 3.7375 1.5 6.5 1.5C9.2625 1.5 11.5 3.7375 11.5 6.5C11.5 9.2625 9.2625 11.5 6.5 11.5Z' fill='%23372D68'/%3E%3C/svg%3E");
    border-color: #372d68;
    border-width: 1px;
}

::placeholder {
    color: #cdcdcd;
    opacity: 1;
    font-size: 14px;
}

.row-based-table button {
    font-family: Lato, sans-serif;
    color: #ffffff;
    background-color: #6d5bd0;
    font-size: 18px;
    line-height: 24px;
    height: 36px;
    font-weight: 400;
    margin: 0 15px 0 0;
    border-radius: 3px;
    padding: 0px 15px 2px 14px;
    width: unset;
    border-style: none;
    text-decoration: none;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 9px 8px;
    cursor: pointer;
    float: left;
}

.row-based-table .row-based-table-actions button {
    background-color: #f5f5f7;
    color: #cfcdd4;
    cursor: unset;
    pointer-events: none;
}

.row-based-table .row-based-table-actions button.is-activated {
    background-color: #6d5bd0;
    color: #ffffff;
    cursor: pointer;
    pointer-events: all;
}

.row-based-table .row-based-table-actions button.reject-button.is-activated .mask {
    background-color: #ffffff;
}

.row-based-table .row-based-table-actions button.reject-button .mask {
    background-color: #cfcdd4;
    mask-image: url(/src/Assets/Images/reject.svg);
    mask-repeat: no-repeat;
    float: left;
    width: 24px;
    height: 22px;
    margin: 3px 6px 0 0px;
}

.row-based-table .row-based-table-actions button.approve-button.is-activated .mask {
    background-color: #ffffff;
}

.row-based-table .row-based-table-actions button.approve-button .mask {
    background-color: #cfcdd4;
    mask-image: url(/src/Assets/Images/approve.svg);
    mask-repeat: no-repeat;
    float: left;
    width: 24px;
    height: 22px;
    margin: 2px 6px 0 1px;
}

.row-based-table .row-based-table-actions button.approve-button.is-activated:hover {
    background-color: #372d68;
}

.row-based-table .row-based-table-actions button.reject-button.is-activated:hover {
    background-color: #372d68;
}

.row-based-table .row-based-table-actions button.compare-button.is-activated .mask {
    background-color: #ffffff;
}

.row-based-table .row-based-table-actions button.compare-button .mask {
    background-color: #cfcdd4;
    mask-image: url(/src/Assets/Images/compare.svg);
    mask-repeat: no-repeat;
    float: left;
    width: 24px;
    height: 22px;
    margin: 4px 6px 0 0px;
}

.row-based-table .row-based-table-actions button.compare-button.is-activated:hover {
    background-color: #372d68;
}

.column-based-table-wrapper.new-no-float {
    float: unset;
    clear: both;
}
.column-based-table-wrapper.new-no-float .labels-column {
    width: unset;
    flex-grow: 1;
}

.column-based-table-wrapper.new-no-float.animation-class {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 255, 0),
        rgba(0, 0, 255, 0),
        rgba(0, 0, 255, 0.05),
        rgba(0, 0, 255, 0),
        rgba(0, 0, 255, 0)
    );
    background-size: 300% 100%;
    background-repeat: no-repeat;
    background-position: 300% 0;
    animation: table-background-animation 300ms;
    animation-timing-function: linear;
}
.column-based-table-wrapper.new-no-float.animation-class.left {
    animation-direction: reverse;
}

@keyframes table-background-animation {
    from {
        background-position: 0% 0;
    }
    to {
        background-position: 100% 0;
    }
}

.column-based-table-wrapper .table-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.column-based-table-wrapper.no-wrap .table-row {
    flex-wrap: nowrap;
}
.column-based-table-wrapper .table-row > div {
    flex-shrink: 0;
}

.column-based-table-wrapper {
    margin: 0 0 0 80px;
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 255, 0),
        rgba(0, 0, 255, 0),
        rgba(0, 0, 255, 0.05),
        rgba(0, 0, 255, 0),
        rgba(0, 0, 255, 0)
    );
    background-size: 300% 100%;
    float: left;
}

.column-based-table-wrapper.new-no-float .column-based-table {
    float: unset;
}

.table-button-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.column-based-table {
    float: left;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    width: 100%;
    margin: 31px 0 20px 0;
}

.column-based-table .column {
    float: left;
    box-sizing: border-box;
    flex-direction: column;
    min-width: 160px;
}

.column-based-table .labels-column {
    min-width: 250px;
    max-width: 397px;
    width: 100%;
    text-align: left;
}

.column-based-table .labels-column.account-labels {
    min-width: 460px;
    max-width: 460px;
}

.column-based-table .labels-column.account-labels .cell {
    min-width: 422px;
    max-width: 422px;
}

.column-based-table .labels-column .cell {
    padding-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.column-based-table .cell.tiny-cell,
.column-based-table .tiny-row .cell {
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0.9px;
    min-height: 16px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 13px;
    padding-top: 2px;
    padding-bottom: 6px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.035);
    background-position: calc(100% - 9px) 11px;
}
/*
  Apparently, fonts are rendered differnetly in Firefox, Safari and Chrome,
  so we need to alter for the different browsers to avoid incorrect line
  breaks in the tiny-rows.
*/
/* Firefox: (CSS-query sourced from here: https://stackoverflow.com/a/953491) */
@-moz-document url-prefix() {
    .column-based-table .cell.tiny-cell,
    .column-based-table .tiny-row .cell {
        letter-spacing: 0;
    }
}
/* Safari: (CSS-query sourced from here: https://gist.github.com/jbutko/6718701?permalink_comment_id=2630350#gistcomment-2630350) */
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .column-based-table .cell.tiny-cell,
        .column-based-table .tiny-row .cell {
            letter-spacing: -0.1px;
        }
    }
}

.column-based-table .labels-column .cell.tiny-cell,
.column-based-table .tiny-row .labels-column .cell {
    margin-left: 0;
    padding-right: 10px;
    width: calc(100% - 30px);
}

.column-based-table .cell.hidden-row {
    display: none;
}

.column-based-table .cell.trend-arrow-up {
    background-image: url(/src/Assets/Images/trend-arrow-up.svg);
    background-position: calc(100% - 12px) calc(100% - 16px);
}

.column-based-table .cell.trend-arrow-down {
    background-image: url(/src/Assets/Images/trend-arrow-down.svg);
    background-position: calc(100% - 12px) calc(100% - 16px);
}

.column-based-table .cell.trend-arrow-same {
    background-position: calc(100% - 12px) calc(100% - 16px);
}

.column-based-table .cell .anomaly-icon {
    background-image: url(/src/Assets/Images/anomaly-icon.svg);
    background-position: 0 3px;
    width: 17px;
    height: 15px;
    display: inline-block;
    margin: 0px 10px 0 0;
}

.column-based-table .cell .tooltip {
    position: absolute;
    margin-top: 15px;
    margin-left: 20px;
    background-color: #f2f1fa;
    color: #000000;
    padding: 10px;
    border-radius: 5px;
    display: none;
    max-width: 300px;
    white-space: normal;
    border: 1px #cbc6ec solid;
    text-align: left;
    text-indent: 0;
}

.column-based-table .cell .cell:hover .tooltip {
    display: block;
}

.column-based-table .table-row:hover,
.column-based-table .cell.is-hovered {
    background-color: rgba(35, 35, 75, 0.04);
    /* background-color:#f2f2f2; */
}

.column-based-table .cell.chart-launcher .mask {
    mask-image: url(/src/Assets/Images/open-chart.svg);
    mask-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    float: left;
    width: 14px;
    height: 14px;
    margin: 4px 6px 0 0;
}

.column-based-table a {
    color: #6d5bd0;
    text-decoration: none;
    position: absolute;
    margin-left: -81px;
    margin-top: -5px;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
    text-align: right;
    display: inline-block;
}

.column-based-table a:hover {
    border-color: rgba(0, 0, 0, 0);
    color: #372d68;
    transition: all 0.15s ease-out;
    text-decoration: underline;
}

.column-based-table .column-header .budget-data,
.column-based-table .budget-data .column-header:nth-of-type(1) {
    height: 34px;
    overflow: visible;
    padding: 0;
    background-color: #eeebff;
}

.column-based-table .column-header .budget-data .cell {
    padding-left: 33px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.column-based-table .my-numbers-data {
    background-color: rgba(120, 200, 200, 0.07);
}
.column-based-table .column-header .my-numbers-data,
.column-based-table .my-numbers-data .column-header:nth-of-type(1) {
    background-color: #88dad6;
}

.column-based-table .my-numbers-data .column-header:nth-of-type(1).is-hovered {
    background-color: #88dad6;
}

.column-based-table .my-numbers-data .parent-cell .column-header {
    background-color: unset;
    width: 100%;
    padding-right: 30px;
}

.column-based-table .cell {
    min-height: 26px;
    padding: 8px 33px 0 0;
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) calc(100% - 20px);
    overflow: hidden;
}

.column-based-table .my-numbers-data .cell.is-hovered {
    background-color: rgba(100, 120, 8, 0.05);
}

.column-based-table .table-row .nested-row {
    width: 100%;
}

.column-based-table .bold .cell,
.column-based-table .expanded .cell,
.column-based-table .cell.bold {
    font-weight: 700;
}

.column-based-table .cell.is-hidden {
    display: none;
}

.column-based-table :not(.labels-column) > .cell,
.column-based-table .cell.number {
    text-align: right;
}

.column-based-table .table-row.space-under,
.column-based-table .cell.space-under {
    margin-bottom: 30px;
}

.column-based-table .empty-row .cell {
    height: 22px;
    min-height: 22px;
}

.column-based-table .line-under > * > .cell,
.column-based-table .cell.line-under {
    border-bottom: 1px #efefef solid;
}

.column-based-table .line-under-strong > * > .cell,
.column-based-table .cell.line-under-strong {
    border-bottom: 1px #160a25 solid;
}
.column-based-table .table-row.summary .cell,
.column-based-table .cell.summary {
    border-top: 2px #160a25 solid;
    border-bottom: none;
}

.column-based-table .parent-cell .cell.summary {
    font-weight: 400;
}

.column-based-table .labels-column .parent-cell .cell:not(.parent-cell) {
    width: calc(100% - 2px);
}

.column-based-table .level-1-header .cell,
.column-based-table .cell.level-1-header {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 22px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: none;
    margin-bottom: 15px;
    border-top: 1px #efefef solid;
}

.column-based-table .level-1-header .labels-column .cell::before,
.column-based-table .labels-column .cell.level-1-header::before {
    content: '';
    display: block;
    position: absolute;
    border-color: transparent #efefef;
    border-style: solid;
    border-width: 16px 16px 16px 0px;
    height: 0px;
    width: 0px;
    transform: rotate(45deg);
    margin-top: -20px;
    margin-left: -30px;
}

.column-based-table .level-1-footer .cell,
.column-based-table .cell.level-1-footer {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 22px;
    font-weight: 700;
    text-transform: uppercase;
    border-top: none;
    border-bottom: 1px #efefef solid;
}

.column-based-table .level-1-footer .labels-column .cell::before,
.column-based-table .labels-column .cell.level-1-footer::before {
    content: '';
    display: block;
    position: absolute;
    border-color: transparent #efefef;
    border-style: solid;
    border-width: 16px 16px 16px 0px;
    height: 0px;
    width: 0px;
    transform: rotate(-45deg);
    margin-top: 6px;
    margin-left: -30px;
}

.column-based-table .labels-column .parent-cell {
    text-align: left;
    position: relative;
    float: left;
    padding-left: 5px;
    overflow: visible;
    width: calc(100% - 38px);
}

.column-based-table .parent-cell .cell {
    padding-right: 0;
    display: none;
    text-indent: 10px;
    font-weight: 400;
    width: 100%;
    padding-right: 30px;
}

.column-based-table .parent-cell .parent-cell {
    margin-top: 0;
    padding-top: 0px;
}

.column-based-table .labels-column .parent-cell .parent-cell {
    padding-right: 8px;
}

.column-based-table .parent-cell .parent-cell .cell {
    padding-right: 30px;
}

.column-based-table .labels-column .parent-cell .parent-cell .cell {
    padding-right: 3px;
}

.column-based-table .parent-cell .parent-cell.is-open {
    margin-left: 0;
    margin-top: 2px;
    padding-bottom: 10px;
}

.column-based-table .parent-cell.is-open .parent-cell .cell {
    display: none;
}

.column-based-table .parent-cell.is-open .parent-cell.is-open .cell {
    display: block;
}

.column-based-table .parent-cell .parent-cell.is-open .is-hidden {
    display: none;
}

.column-based-table .labels-column .parent-cell .parent-cell .cell {
    margin-left: 0px;
}

.column-based-table .labels-column .parent-cell .parent-cell {
    text-align: left;
    padding-left: 0;
    margin-left: 0px;
    padding-left: 25px;
    text-indent: 15px;
}

.column-based-table .labels-column .parent-cell .parent-cell.is-open {
    margin-top: 0px;
    padding-bottom: 60px;
}

.column-based-table .labels-column .parent-cell.is-open .parent-cell .unfold-icon {
    mask-image: url(/src/Assets/Images/unfold-icon.svg);
    width: 18px;
    height: 18px;
    mask-position: 0px 6px;
    mask-size: 10px 10px;
    margin-left: -20px;
    margin-top: 13px;
    background-color: #cbc6ec;
}

.column-based-table .labels-column .parent-cell.is-open .parent-cell.is-open .unfold-icon {
    mask-image: url(/src/Assets/Images/fold-icon.svg);
}

.column-based-table .labels-column .parent-cell.is-open .parent-cell .unfold-icon:hover {
    background-color: #372d68;
}

.column-based-table .table-row .unfold-icon {
    mask-image: url(/src/Assets/Images/unfold-icon.svg);
    position: absolute;
    margin-top: 8px;
    margin-left: -20px;
    mask-repeat: no-repeat;
    mask-position: 4px 3px;
    background-color: #cbc6ec;
    display: block;
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.column-based-table .table-row .unfold-icon.is-open {
    mask-image: url(/src/Assets/Images/fold-icon.svg);
    mask-position: 4px 8px;
}
.column-based-table .nested-row > ::before {
    content: '';
    position: absolute;
    margin-left: -32px;
    height: 48px;
    width: 1px;
    background-color: #cbc6ec;
}

.column-based-table .parent-cell .unfold-icon {
    mask-image: url(/src/Assets/Images/unfold-icon.svg);
    float: left;
    width: 18px;
    height: 50px;
    position: absolute;
    margin-top: -18px;
    margin-left: -28px;
    cursor: pointer;
    display: none;
    mask-repeat: no-repeat;
    mask-position: 6px 22px;
    background-color: #cbc6ec;
}

.column-based-table .parent-cell.is-open .unfold-icon {
    mask-image: url(/src/Assets/Images/fold-icon.svg);
    margin-top: -1px;
}

.column-based-table .parent-cell .unfold-icon:hover {
    background-color: #372d68;
}

.column-based-table .parent-cell.is-open {
    padding-top: 40px;
    padding-bottom: 8px;
}

.column-based-table .parent-cell.is-open .parent-cell {
    background-position: calc(100% - 12px) calc(100% - 44px);
}

.column-based-table .parent-cell.is-open .parent-cell.is-open {
    padding-top: 45px;
    padding-bottom: 24px;
}

.column-based-table .labels-column .parent-cell.is-open .parent-cell.is-open {
    padding-top: 0;
    padding-bottom: 59px;
    margin-top: 2px;
}

.column-based-table .parent-cell .parent-cell {
    background-position: calc(100% - 12px) calc(100% - 22px);
}

.column-based-table .cell.parent-cell.is-open.space-under {
    margin-bottom: 10px;
}

.column-based-table .parent-cell.is-open .cell.is-hidden {
    display: none;
}

.column-based-table .labels-column .parent-cell .unfold-icon {
    display: block;
}

.column-based-table .labels-column .parent-cell.is-open {
    margin-top: 0;
    padding-bottom: 38px;
    padding-top: 0;
}

.column-based-table .parent-cell.is-open .cell {
    display: block;
}

.column-based-table .labels-column .parent-cell.is-open .cell {
    left: 0;
}

.column-based-table .labels-column .parent-cell.is-open .cell::before {
    background-color: #cbc6ec;
    width: 1.2px;
    height: 48px;
    position: absolute;
    margin-left: -22px;
    margin-top: -12px;
    content: '';
    display: block;
    z-index: 10;
}

.column-based-table .labels-column .parent-cell.is-open .parent-cell::before {
    display: none;
}

.column-based-table .parent-cell .parent-sum {
    display: block;
    height: 26px;
}

.column-based-table .parent-cell .parent-heading {
    display: none;
}

.column-based-table .parent-cell.is-open .parent-heading {
    display: none;
}

.column-based-table .parent-cell.is-open .parent-sum {
    display: block;
    font-weight: 700;
    line-height: 40px;
    height: 30px;
}

.column-based-table .parent-cell.is-open .parent-cell .parent-sum {
    height: 43px;
}

.column-based-table .parent-cell.is-open .parent-cell.is-open {
    background-position: calc(100% - 12px) calc(100% - 35px);
}

.column-based-table .parent-cell.is-open .parent-cell.is-open .parent-sum {
    height: 35px;
}

.column-based-table .labels-column .parent-cell .parent-sum {
    display: block;
}

.column-based-table .labels-column .parent-cell .parent-heading {
    display: none;
}

.column-based-table .labels-column .parent-cell.is-open .parent-heading {
    font-weight: 700;
    display: block;
    height: 40px;
    line-height: 43px;
}

.column-based-table .labels-column .parent-cell.is-open .parent-sum {
    display: none;
}

.column-based-table .labels-column .parent-cell .parent-cell .parent-heading {
    margin-left: -12px;
    height: 43px;
    line-height: 43px;
}

.column-based-table .labels-column .parent-cell.is-open .parent-cell .parent-heading {
    margin-left: -12px;
    height: 43px;
    line-height: 43px;
}

.column-based-table .labels-column .parent-cell.is-open .parent-cell.is-open .parent-heading {
    margin-left: -12px;
    height: 45px;
    line-height: 45px;
}

.top-buttons-block {
    margin-top: 15px;
    margin-left: 50px;
}

.top-buttons-block a {
    font-family: Lato, sans-serif;
    background-color: #ffffff;
    color: #6d5bd0;
    font-size: 16px;
    line-height: 28px;
    height: 24px;
    font-weight: 400;
    margin: 0 4px 0 0;
    border-radius: 3px;
    padding: 8px 15px 7px 15px;
    text-decoration: none;
}
.top-buttons-block a.active,
.top-buttons-block a:hover {
    background-color: #372d68;
    color: #ffffff;
    transition: all 0.15s ease-out;
}

.content-wrapper .top-buttons {
    margin: 15px 0 0 0;
    float: left;
}

.content-wrapper .charts-wrapper button,
.content-wrapper .top-buttons a {
    font-family: Lato, sans-serif;
    background-color: #ffffff;
    color: #6d5bd0;
    font-size: 16px;
    line-height: 23px;
    height: 24px;
    font-weight: 400;
    float: left;
    margin: 0 4px 0 0;
    border-radius: 3px;
    padding: 8px 15px 7px 15px;
    width: unset;
    border-style: none;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
}

.content-wrapper .charts-wrapper button .mask,
.content-wrapper .top-buttons a .mask {
    background-color: #6d5bd0;
    mask-repeat: no-repeat;
    float: left;
    width: 24px;
    height: 22px;
    margin: 0px 10px 0 1px;
}

.content-wrapper .charts-wrapper button {
    border: 1px #6d5bd0 solid;
    border-radius: 4px;
}

.content-wrapper .top-buttons a:hover,
.content-wrapper .top-buttons a.current,
.content-wrapper .top-buttons a.selected {
    font-family: Lato, sans-serif;
    background-color: #372d68;
    color: #ffffff;
    transition: all 0.15s ease-out;
}

.content-wrapper .top-buttons a.current,
.content-wrapper .top-buttons a.selected {
    pointer-events: none;
    cursor: default;
}

.content-wrapper .top-buttons a.accounts:hover .mask,
.content-wrapper .top-buttons a.accounts.current:hover .mask,
.content-wrapper .top-buttons a.accounts.current .mask {
    background-color: #ffffff;
}

.content-wrapper .top-buttons a.accounts .mask {
    mask-image: url(/src/Assets/Images/accounts.svg);
    margin: 1px 3px 0 1px;
}

.content-wrapper .top-buttons a.by-month:hover .mask,
.content-wrapper .top-buttons a.by-month.current:hover .mask,
.content-wrapper .top-buttons a.by-month.current .mask {
    background-color: #ffffff;
}

.content-wrapper .top-buttons a.by-month .mask {
    mask-image: url(/src/Assets/Images/monthly.svg);
}

.content-wrapper .top-buttons a.by-chart {
    display: none; /* temp fix hiding before absolute sure we will delete chart views */
}

.content-wrapper .top-buttons a.by-chart:hover .mask,
.content-wrapper .top-buttons a.by-chart.current:hover .mask,
.content-wrapper .top-buttons a.by-chart.current .mask {
    background-color: #ffffff;
}

.content-wrapper .top-buttons a.by-chart .mask {
    mask-image: url(/src/Assets/Images/open-chart.svg);
    margin: 4px 6px 0 1px;
    mask-size: 18px 18px;
}

.content-wrapper .top-buttons a.by-year:hover .mask,
.content-wrapper .top-buttons a.by-year.current:hover .mask,
.content-wrapper .top-buttons a.by-year.current .mask {
    background-color: #ffffff;
}

.content-wrapper .top-buttons a.by-year .mask {
    mask-image: url(/src/Assets/Images/yearly.svg);
}

.content-wrapper .chart-view-options .top-buttons {
    margin: 20px 0 0 80px;
}

.column-based-table-wrapper .save-file.as-pdf .mask {
    mask-image: url(/src/Assets/Images/save-as-pdf.svg);
    margin-top: 8px;
    height: 17px;
    background-color: #b11010;
}

.column-based-table-wrapper .save-file.as-pdf:hover .mask {
    background-color: #b11010;
}

.column-based-table-wrapper .save-file.as-csv .mask {
    mask-image: url(/src/Assets/Images/save-as-csv.svg);
    margin-top: 8px;
    height: 17px;
    background-color: #148044;
}

.column-based-table-wrapper .save-file.as-csv:hover .mask {
    background-color: #148044;
}

.column-based-table-wrapper .top-filters {
    width: 50%;
    margin: 15px 0 0 0;
    float: left;
    min-width: 400px;
}

.column-based-table-wrapper .top-step-buttons {
    width: 450px;
    margin: 15px 81px 0 0;
    float: right;
    text-align: right;
}

#topStepButtons {
    display: none;
}

.column-based-table-wrapper .show-budgets,
.column-based-table-wrapper .save-file,
.column-based-table-wrapper .filter-toggle,
.column-based-table-wrapper .foldable-main-toggle,
.column-based-table-wrapper .hidden-values-toggle {
    font-family: Lato, sans-serif;
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    color: #6d5bd0;
    padding: 0 12px 0 12px;
    cursor: pointer;
    height: 32px;
    margin: 0;
    display: none;
    float: left;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
}

.column-based-table-wrapper .show-budgets .mask,
.column-based-table-wrapper .save-file .mask,
.column-based-table-wrapper .filter-toggle .mask,
.column-based-table-wrapper .foldable-main-toggle .mask,
.column-based-table-wrapper .hidden-values-toggle .mask {
    mask-repeat: no-repeat;
    background-color: #6d5bd0;
    float: left;
    width: 15px;
    height: 15px;
    margin: 10px 6px 0 0;
}

.column-based-table-wrapper .show-budgets:hover,
.column-based-table-wrapper .save-file:hover,
.column-based-table-wrapper .filter-toggle:hover,
.column-based-table-wrapper .foldable-main-toggle:hover,
.column-based-table-wrapper .hidden-values-toggle:hover {
    color: #372d68;
    border-color: #372d68;
    transition: all 0.15s ease-out;
}

.column-based-table-wrapper .show-budgets:hover .mask,
.column-based-table-wrapper .save-file:hover .mask,
.column-based-table-wrapper .filter-toggle:hover .mask,
.column-based-table-wrapper .foldable-main-toggle:hover .mask,
.column-based-table-wrapper .hidden-values-toggle:hover .mask {
    background-color: #372d68;
    transition: all 0.15s ease-out;
}

.column-based-table-wrapper .show-budgets.is-visible,
.column-based-table-wrapper .save-file.is-visible,
.column-based-table-wrapper .filter-toggle.is-visible,
.column-based-table-wrapper .foldable-main-toggle.is-visible,
.column-based-table-wrapper .hidden-values-toggle.is-visible {
    display: inline-block;
}

.column-based-table-wrapper .show-budgets .mask {
    mask-image: url(/src/Assets/Images/toggle-off.svg);
}

.column-based-table-wrapper .show-budgets.is-active .mask {
    mask-image: url(/src/Assets/Images/toggle-on.svg);
}

.button-group .btn.fold:before,
.column-based-table-wrapper .foldable-main-toggle .mask {
    mask-image: url(/src/Assets/Images/unfold-main-toggle-icon.svg);
}

.button-group .btn.fold.is-expanded:before,
.column-based-table-wrapper .foldable-main-toggle.is-active .mask {
    mask-image: url(/src/Assets/Images/fold-main-toggle-icon.svg);
}

.column-based-table-wrapper .hidden-values-toggle .mask {
    mask-image: url(/src/Assets/Images/hide-hideable-toggle-icon.svg);
    margin-top: 9px;
    width: 17px;
}

.column-based-table-wrapper .hidden-values-toggle.is-active .mask {
    mask-image: url(/src/Assets/Images/show-hideable-toggle-icon.svg);
    margin-top: 11px;
    width: 17px;
}

.column-based-table-wrapper .step-button {
    background-repeat: no-repeat;
    background-position: 0 0;
    display: block;
    width: 40px;
    height: 40px;
    float: right;
    margin: 5px 0 0 0;
    cursor: pointer;
    border: 1px #ffffff solid;
    border-radius: 3px;
}

.button-group {
    display: flex;
    align-items: center;
}

.button-group .step-header {
    width: 200px;
    text-align: center;
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 15px;
}
.button-group .btn {
    color: #6d5bd0;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 5px;
}
.button-group .btn:hover {
    color: #372d68;
    border-color: #372d68;
}
.button-group .btn.inactive:hover {
    border-color: transparent;
}
.button-group .btn:before {
    background-color: #6d5bd0;
    display: inline-block;
    content: '';
    mask-position: center;
}
.button-group.action-group {
    flex-wrap: wrap;
}
.button-group.action-group .btn {
    margin-top: 6px;
}
.button-group.action-group .choose-budget select {
    max-width: 120px;
}
.step-button-group .btn:before {
    width: 40px;
    height: 40px;
}
.step-button-group .btn.inactive:before {
    background-color: #cfcdd4;
}
.step-button-group .btn.left:before {
    mask-image: url(/src/Assets/Images/step-left.svg);
}
.step-button-group .btn.left-fast:before {
    mask-image: url(/src/Assets/Images/step-left-fast.svg);
}
.step-button-group .btn.right:before {
    mask-image: url(/src/Assets/Images/step-right.svg);
}
.step-button-group .btn.right-fast:before {
    mask-image: url(/src/Assets/Images/step-right-fast.svg);
}

.column-based-table-wrapper .step-button:hover {
    border-color: #372d68;
}

.button-group.action-group .btn {
    padding: 0 5px;
    height: 30px;
    line-height: 30px;
}
.button-group.action-group .btn:before {
    vertical-align: sub;
    margin: 0 7px;
}
.button-group .fold.btn:before {
    width: 14px;
    height: 14px;
}
.button-group .csv.btn:before {
    mask-image: url(/src/Assets/Images/save-as-csv.svg);
    height: 17px;
    width: 14px;
    background-color: #148044;
}
.button-group .pdf.btn:before {
    mask-image: url(/src/Assets/Images/save-as-pdf.svg);
    height: 17px;
    width: 14px;
    background-color: #b11010;
}

.column-based-table-wrapper .step-button .mask {
    mask-repeat: no-repeat;
    float: left;
    width: 44px;
    height: 44px;
    margin: -1px 1px 1px -2px;
}

.column-based-table-wrapper .step-button.inactive {
    cursor: unset;
    pointer-events: none;
}

.column-based-table-wrapper .step-button.left .mask {
    mask-image: url(/src/Assets/Images/step-left.svg);
    background-color: #6d5bd0;
}
.column-based-table-wrapper .step-button.right .mask {
    mask-image: url(/src/Assets/Images/step-right.svg);
    background-color: #6d5bd0;
}
.column-based-table-wrapper .step-button.left.inactive .mask {
    background-color: #cfcdd4;
}
.column-based-table-wrapper .step-button.right.inactive .mask {
    background-color: #cfcdd4;
}
.column-based-table-wrapper .step-button.left:hover .mask {
    background-color: #372d68;
}
.column-based-table-wrapper .step-button.right:hover .mask {
    background-color: #372d68;
}
.column-based-table-wrapper .step-button.left-fast .mask {
    mask-image: url(/src/Assets/Images/step-left-fast.svg);
    background-color: #6d5bd0;
}
.column-based-table-wrapper .step-button.right-fast .mask {
    mask-image: url(/src/Assets/Images/step-right-fast.svg);
    background-color: #6d5bd0;
}
.column-based-table-wrapper .step-button.left-fast.inactive .mask {
    background-color: #cfcdd4;
}
.column-based-table-wrapper .step-button.right-fast.inactive .mask {
    background-color: #cfcdd4;
}
.column-based-table-wrapper .step-button.left-fast:hover .mask {
    background-color: #372d68;
    transition: all 0.15s ease-out;
}
.column-based-table-wrapper .step-button.right-fast:hover .mask {
    background-color: #372d68;
    transition: all 0.15s ease-out;
}

.column-based-table-wrapper .current-span {
    display: block;
    width: 200px;
    height: 30px;
    float: right;
    margin: 0 10px;
    text-align: center;
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    padding: 15px 0 0 0;
    white-space: nowrap;
}

.data-missing-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    float: left;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-bottom: 15px;
    background-color: #ffffff;
    font-size: 13px;
}

.choose-at-least-one-budget,
.chart-data-missing,
.data-missing-wrapper .company-data-missing-message,
.no-pending-companies-message {
    display: inline-block;
    float: none;
    min-width: 400px;
    font-size: 16px;
    padding: 10px 4px 12px 16px;
    border-radius: 6px;
    color: #da546c;
    background-color: rgba(218, 84, 108, 0.12);
    background-repeat: no-repeat;
    background-position: 4px 7px;
    margin: 100px auto;
    text-align: center;
}

.choose-at-least-one-budget,
.chart-data-missing {
    margin-top: 100px;
    width: 250px;
    display: block;
}
.choose-at-least-one-budget {
    color: black;
    background-color: rgba(83, 193, 226, 0.12);
}

.overlay-menu {
    margin: 30px 0 0 0;
    float: left;
    clear: left;
}

.overlay-menu ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
}

.overlay-menu ul li {
    height: 36px;
    font-family: Lato, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.overlay-menu ul a,
.overlay-menu ul button {
    padding: 14px 16px 12px 16px;
    color: #6d5bd0;
    text-decoration: none;
    border: none;
    cursor: pointer;
    border-bottom: 4px transparent solid;
    border-radius: 6px 6px 0 0;
    letter-spacing: 0.2px;
    margin: 0 5px 0 0;
    min-width: 180px;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    display: inline;
}

.overlay-menu ul a:hover,
.overlay-menu ul button:hover,
.overlay-menu ul a.active,
.overlay-menu ul button.active {
    background-color: #eeebff;
    border-bottom-color: #6d5bd0;
    transition: all 0.15s ease-out;
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.disappear {
    animation: fade-out 5s cubic-bezier(1, 0, 0, 0);
    opacity: 0;
}

.button.report-generator-opener {
    padding-bottom: 0px;
    min-height: 31px;
    float: left;
    margin-left: 0;
    margin-top: -6px;
    font-size: 15px;
    line-height: 30px;
    padding-left: 30px;
    min-width: unset;
}

.button.report-generator-opener:hover {
    background-color: #372d68;
}

.button.report-generator-opener .icon {
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 18px;
    margin-right: 5px;
    margin-top: 5px;
    margin-left: -21px;
    border-radius: 0;
    background-repeat: no-repeat;
    background-position: -1px 0px;
    background-image: url(/src/Assets/Images/report.svg);
}

.report-generator-wrapper {
    position: relative;
    top: 7%;
    background-color: #ffffff;
    color: #000000;
    width: calc(100% - 60px);
    max-width: 800px;
    z-index: 210;
    border-radius: 5px;
    text-align: left;
    margin: 0 auto;
    padding: 20px 30px;
    float: none;
    display: inline-block;
    font-family: Lato, sans-serif;
    line-height: 30px;
    font-size: 18px;
    overflow-y: auto;
    max-height: 80%;
}

.report-generator-wrapper p {
    font-size: 14px;
}

.report-generator-overlay {
    background-color: rgba(45, 55, 104, 0.3);
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0;
    text-align: center;
    padding: 0;
    margin: 0;
    z-index: 200;
    display: block;
}

.report-generator-overlay h2 {
    font-family: Francois One, sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 37px;
    color: #160a25;
    float: left;
    text-align: left;
    margin: 5px 0;
}

.report-generator-overlay h3 {
    font-family: Francois One, sans-serif;
    margin: 10px 0 0 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: #160a25;
}

.report-generator-overlay .report-generator-close {
    position: absolute;
    float: right;
    top: -27px;
    right: -60px;
    color: #6d5bd0;
    font-size: 18px;
    line-height: 38px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    width: 70px;
    height: 40px;
    margin: 47px 78px 0 0;
    padding: 0 15px;
    text-align: left;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
}

.report-generator-overlay .report-generator-close:hover {
    color: #372d68;
    border-color: #372d68;
    transition: all 0.15s ease-out;
}

.report-generator-overlay .report-generator-close .mask {
    background-color: #6d5bd0;
    mask-image: url(/src/Assets/Images/help-close.svg);
    mask-repeat: no-repeat;
    float: right;
    width: 16px;
    height: 16px;
    margin: 13px 0 0 0;
}

.report-generator-overlay .report-generator-close:hover .mask {
    background-color: #372d68;
}

.report-generator-overlay .budgets-block .multi {
    min-height: 5em;
}

.report-generator-overlay .budgets-block .budget-select {
    display: flex;
    flex-direction: row;
}

.report-generator-overlay .budgets-block .checkbox-container + div,
.report-generator-overlay .budgets-block .checkbox-container + span {
    font-size: 13px;
    position: relative;
    top: -4px;
}

.report-generator-overlay .budgets-block span {
    margin: 0 1em 0 0;
    color: #aaa;
}

.report-generator-overlay .budgets-block.enabled span {
    color: #000;
}

.report-generator-overlay .report-generator-item {
    float: left;
    text-align: left;
    margin-right: 30px;
}

.report-generator-overlay .report-generator-scroll-container {
    width: 100%;
}

.report-generator-overlay .report-generator-form {
    clear: both;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.report-generator-overlay .year-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}

.report-generator-overlay .month-selector-wrapper {
    margin-bottom: 5em;
}

.report-generator-overlay .reports-month-selector {
    clear: both;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr;
}

.report-generator-overlay .reports-month-wrapper {
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr;
}

.report-generator-overlay .reports-month-wrapper.comparison {
    grid-template-columns: 25px 1fr 1fr;
}

.report-generator-overlay .comparison .checkbox-container {
    margin-top: 35px;
}

.report-generator-overlay .reports-dropdown-wrapper {
    height: 5em;
}

.input-wrapper.months > div {
    text-transform: capitalize;
}

.content-wrapper .charts-wrapper.company-charts .report-generator-overlay h4 {
    margin: 20px 0 15px 0;
}

.report-generator-overlay h4 {
    margin: 0.8em 0 0.5em 0;
}

.report-generator-overlay p em {
    clear: left;
    float: left;
    margin-top: 15px;
}

.report-generator-overlay h4:nth-of-type(2) {
    position: relative;
    top: 0px;
    margin-bottom: 20px;
    z-index: 1;
    float: left;
    padding-top: 10px;
    margin-top: 0px;
    height: 20px;
    padding-bottom: 0px;
    min-width: 80%;
}

.report-generator-overlay .report-generator-row {
    /* float:left; */
    font-size: 14px;
    clear: left;
    width: 100%;
}

.report-generator-overlay .report-quarter-financial-year {
    float: left;
    clear: left;
    font-weight: bold;
    margin-right: 20px;
}

.report-generator-overlay .checkbox-wrapper {
    min-width: 100px;
}

.report-generator-overlay .checkbox-container {
    display: block;
    float: left;
    margin-right: 35px;
}

.report-generator-overlay .checkbox-container div {
    margin-left: 32px;
    font-size: 13px;
    line-height: 20px;
}

.report-generator-overlay .checkbox-container input:hover ~ .checkmark,
.report-generator-overlay .checkbox-container input:checked ~ .checkmark {
    border-color: #6d5bd0 !important;
}

.report-generator-overlay .button {
    margin-top: 20px;
    clear: left;
    text-align: center;
    float: left;
}

.report-generator-overlay .button:disabled {
    cursor: no-drop;
    background-color: #aaa;
}

.compare-wrapper {
    position: relative;
    top: 10%;
    background-color: #ffffff;
    color: #000000;
    width: calc(100% - 60px);
    max-width: 800px;
    height: 80%;
    min-height: 550px;
    max-height: 800px;
    z-index: 210;
    border-radius: 5px;
    text-align: center;
    margin: 0 auto;
    padding: 5px 30px;
    float: none;
    display: inline-block;
    font-family: Lato, sans-serif;
    line-height: 30px;
    font-size: 18px;
    overflow: hidden;
}

.compare-overlay {
    background-color: rgba(45, 55, 104, 0.3);
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0;
    text-align: center;
    padding: 0;
    margin: 0;
    z-index: 200;
    display: block;
}

.compare-overlay h2 {
    font-family: Francois One, sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 37px;
    color: #160a25;
    float: left;
    text-align: left;
    margin: 18px 0;
    text-indent: 45px;
}

.compare-overlay h3 {
    font-family: Francois One, sans-serif;
    margin: 22px 0 15px 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: #160a25;
}

.compare-overlay .compare-close {
    position: relative;
    float: right;
    top: -30px;
    right: -85px;
    color: #6d5bd0;
    font-size: 18px;
    line-height: 38px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    width: 70px;
    height: 40px;
    margin: 47px 96px 0 0;
    padding: 0 15px;
    text-align: left;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
}

.compare-overlay .compare-close:hover {
    color: #372d68;
    border-color: #372d68;
    transition: all 0.15s ease-out;
}

.compare-overlay .compare-close .mask {
    background-color: #6d5bd0;
    mask-image: url(/src/Assets/Images/help-close.svg);
    mask-repeat: no-repeat;
    float: right;
    width: 16px;
    height: 16px;
    margin: 13px 0 0 0;
}

.compare-overlay .compare-close:hover .mask {
    background-color: #372d68;
}

.compare-overlay .compare-item {
    float: left;
    text-align: left;
    margin-right: 30px;
}

.compare-overlay ul {
    padding: 0;
    list-style: none;
    margin-right: 30px;
}

.compare-overlay li {
    list-style: none;
    margin: 10px 0;
}

.compare-overlay .compare-scroll-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(80vh - 150px);
    float: left;
    width: 100%;
    position: relative;
    margin-top: -15px;
}

.compare-overlay .compare-warnings {
    position: relative;
    margin-top: -20px;
}

.compare-wrapper .chart-wrapper {
    max-width: 740px;
    width: 740px;
}

.compare-wrapper .compare-chart-menu {
    text-align: center;
    width: 100%;
}

.compare-wrapper .compare-chart-menu a,
.compare-wrapper .compare-chart-menu span {
    color: #6d5bd0;
    font-size: 18px;
    line-height: 38px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    height: 38px;
    outline: none;
    margin: 20px 3px 20px 0;
    padding: 8px 13px 9px 12px;
    border: 1px transparent solid;
    border-radius: 4px;
    text-align: left;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
    z-index: 150;
    position: relative;
    text-decoration: none;
    text-align: center;
}

.compare-wrapper .compare-chart-menu a:hover,
.compare-wrapper .compare-chart-menu a.active,
.compare-wrapper .compare-chart-menu span:hover,
.compare-wrapper .compare-chart-menu span.active {
    background-color: #372d68;
    color: #ffffff;
}
.compare-wrapper .compare-chart-menu a.active,
.compare-wrapper .compare-chart-menu span.active {
    pointer-events: none;
}

.compare-wrapper .compare-chart-menu:nth-of-type(2) {
    margin-top: 13px;
}

.compare-wrapper .compare-chart-menu:nth-of-type(2) a {
    margin-top: 13px;
    padding: 6px 11px 7px 10px;
}

.profit-and-loss-account-years-menu,
.balance-sheets-account-years-menu {
    text-align: left;
    width: 100%;
    float: left;
    margin: 0 0 0 80px;
}

.profit-and-loss-account-years-menu a,
.balance-sheets-account-years-menu a {
    color: #6d5bd0;
    font-size: 18px;
    line-height: 38px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    float: left;
    height: 38px;
    outline: none;
    margin: 20px 3px 20px 0;
    padding: 0 11px 0 8px;
    border: 1px transparent solid;
    border-radius: 4px;
    text-align: left;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
    z-index: 150;
    position: relative;
    text-decoration: none;
}

.profit-and-loss-account-years-menu a:hover,
.balance-sheets-account-years-menu a:hover {
    color: #372d68;
    border-color: #372d68;
}

.profit-and-loss-account-years-menu a.active,
.balance-sheets-account-years-menu a.active {
    background-color: #372d68;
    color: #ffffff;
    transition: all 0.15s ease-out;
    pointer-events: none;
}

.help-wrapper {
    position: relative;
    top: 10%;
    background-color: #ffffff;
    color: #000000;
    width: calc(80% - 60px);
    max-width: 800px;
    min-height: 600px;
    z-index: 210;
    border-radius: 5px;
    text-align: center;
    margin: 0 auto;
    padding: 30px;
    float: none;
    display: inline-block;
    font-family: Lato, sans-serif;
    line-height: 24px;
    font-size: 16px;
}

.dialog-overlay {
    background-color: rgba(45, 55, 104, 0.3);
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0;
    padding: 0;
    margin: 0;
    z-index: 200;
    display: block;
}

.dialog-wrapper {
    position: relative;
    top: 10%;
    background-color: #ffffff;
    color: #000000;
    width: calc(80% - 60px);
    max-width: 600px;
    z-index: 210;
    border-radius: 5px;
    margin: 0 auto;
    padding: 30px;
    font-family: Lato, sans-serif;
    line-height: 24px;
    font-size: 16px;
}

.dialog-overlay .dialog-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 2em;
}
.dialog-overlay .dialog-buttons > div {
    color: #6d5bd0;
    padding: 0.2em 0.5em;
    border: 2px solid #6d5bd0;
    border-radius: 0.5em;
    cursor: pointer;
}

.help-overlay {
    background-color: rgba(45, 55, 104, 0.3);
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0;
    text-align: center;
    padding: 0;
    margin: 0;
    z-index: 200;
    display: block;
}

.dialog-overlay-closer,
.help-overlay-closer {
    position: absolute;
    height: 100vh;
    width: 100vw;
}

.help-overlay h2 {
    font-family: Francois One, sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 37px;
    color: #160a25;
    float: left;
    text-align: left;
    margin: 15px 0;
}

.help-overlay h3 {
    font-family: Francois One, sans-serif;
    margin: 0 0 15px 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: #160a25;
}

.help-overlay .help-close {
    position: relative;
    float: right;
    top: -30px;
    right: -85px;
    color: #6d5bd0;
    font-size: 18px;
    line-height: 38px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    width: 76px;
    height: 40px;
    margin: 47px 84px 0 0;
    padding: 0 12px;
    text-align: left;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
}

.help-overlay .help-close .mask {
    background-color: #6d5bd0;
    mask-image: url(/src/Assets/Images/help-close.svg);
    mask-repeat: no-repeat;
    float: left;
    width: 24px;
    height: 22px;
    margin: 12px 1px 0 0px;
}

.help-overlay .help-close:hover {
    border-color: #372d68;
    color: #372d68;
    transition: all 0.15s ease-out;
}
.help-overlay .help-close:hover .mask {
    background-color: #372d68;
}

.help-overlay .help-item {
    float: left;
    text-align: left;
    margin-right: 30px;
}

.help-overlay .help-item tr td {
    vertical-align: top;
}

.help-overlay .help-item tr td:nth-of-type(2) {
    width: 40%;
}

.help-overlay ul {
    padding: 0;
    list-style: none;
    margin-right: 30px;
}

.help-overlay li {
    list-style: none;
    margin: 10px 0;
}

.help-overlay .help-scroll-container {
    overflow: auto;
    height: calc(80vh - 150px);
    float: left;
    width: 100%;
}

.content-wrapper .charts-wrapper {
    max-width: unset;
    width: calc(100% - 40px);
    min-height: 450px;
    overflow: hidden;
    margin: 5px 15px;
    padding: 10px 10px;
    background-color: #ffffff;
}

.content-wrapper .charts-wrapper .chart-wrapper span {
    width: 100%;
}

.content-wrapper .charts-wrapper .chart-wrapper {
    float: left;
}

.content-wrapper .company-info-table {
    font-size: 14px;
    width: 100%;
    max-width: 550px;
    margin-left: 55px;
    float: left;
    margin-top: 2px;
    margin-bottom: 20px;
}

.content-wrapper .company-charts.charts-wrapper {
    min-height: 370px;
}

.content-wrapper .company-info-table div {
    float: left;
    width: calc(60% - 40px);
    padding: 5px 10px 5px 0;
    font-size: 13px;
}
.content-wrapper .company-info-table div:nth-child(odd) {
    clear: left;
    width: calc(40% - 40px);
}

.content-wrapper .company-info-table .company-activity-info {
    float: left;
    font-size: 13px;
    width: calc(100% - 80px);
    border-top: 1px #cdcdcd solid;
    margin-top: 10px;
    padding-top: 10px;
}

.content-wrapper .company-info-table .company-activity-info p {
    margin: 0 0 10px 0;
}

.content-wrapper .charts-wrapper.company-charts h4 {
    margin: 10px 0 20px 50px;
    font-size: 15px;
    color: #6d5bd0;
    font-weight: 400;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table-wrapper {
    width: calc(100% - 55px);
    margin: 0 0 0 55px;
    background-color: transparent;
    background-image: none;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table-wrapper .top-filters {
    display: none;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table-wrapper .top-step-buttons {
    display: none;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table {
    margin: 0 0 10px 0;
    background-color: transparent;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table .data-missing-wrapper {
    margin-left: 0;
    background-color: transparent;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table .labels-column {
    max-width: 250px;
    min-width: 250px;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table .labels-column .cell {
    text-indent: 0;
    max-width: 250px;
    min-width: 212px;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table .column {
    max-width: 150px;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table .cell {
    text-indent: 15px;
    max-width: 150px;
}

.content-wrapper .charts-wrapper.company-charts .column-based-table .cell.tiny-cell span {
    top: -3px;
    padding-right: 9px;
}

.content-wrapper
    .charts-wrapper.company-charts
    .column-based-table
    .my-numbers-data
    .column-header:nth-of-type(1) {
    position: static !important;
    text-align: right !important;
    padding: 8px 30px 0 0 !important;
}

.charts-wrapper .info-boxes {
    border: none;
    box-shadow: 0px 0px 10px lightgrey;
    border-radius: 10px;
    margin-left: 55px;
    margin-top: 0px;
    padding: 10px 0 25px 0;
    margin-right: 25px;
    max-width: 1200px;
    min-width: 800px;
    width: calc(100% - 110px);
    background-color: #fbfbff;
}

.charts-wrapper .info-boxes h5 {
    margin: 10px 0 10px 55px;
    font-size: 12px;
}

.charts-wrapper .chart-box {
    width: 50%;
    float: left;
    max-width: 550px;
    margin-left: 0;
}

.charts-wrapper .chart-box.legends-as-legends .legend-buttons-wrapper {
    float: none;
    display: block;
    margin-top: 350px;
    width: 500px;
    margin-left: 0px;
    text-align: center;
    position: absolute;
    z-index: 11;
}

.charts-wrapper .chart-box.legends-as-legends .legend-buttons-wrapper .legends-button {
    background-color: transparent !important;
    color: #000000;
    float: none;
    display: inline-block;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.charts-wrapper
    .chart-box.legends-as-legends
    .legend-buttons-wrapper
    .legends-button
    .legends-icon {
    display: block;
}

.charts-wrapper .chart-box.legends-as-legends .legend-buttons-wrapper .legends-button::before {
    display: none;
}

.charts-wrapper .chart-box .legend-buttons-wrapper {
    float: right;
    margin-right: 65px;
}

.charts-wrapper .chart-box.no-buttons .legend-buttons-wrapper {
    display: none;
}

.charts-wrapper .chart-box.dropdown .legend-buttons-wrapper {
    position: absolute;
    margin-left: 310px;
    height: 21px;
    max-width: 220px;
    padding: 0;
    width: 100%;
    overflow: hidden;
    display: block;
    text-align: right;
}

.charts-wrapper .chart-box.dropdown .legend-buttons-wrapper::after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    z-index: 11;
    background-color: transparent;
    margin-left: -60px;
    margin-top: 3px;
    transform: rotate(45deg);
    border-style: solid;
    border-width: 4px;
    border-color: transparent #ffffff #ffffff transparent;
}

.charts-wrapper .chart-box.dropdown .legend-buttons-wrapper:hover {
    height: unset;
    min-height: 100px;
    z-index: 11;
}

.legends-button .legends-icon {
    display: none;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    float: left;
    margin-right: 4px;
}

.charts-wrapper .chart-box.dropdown .legend-buttons-wrapper:hover .legends-button {
    position: static;
    float: left;
}

.secondary-legends.dropdown .secondary-legends-button,
.charts-wrapper .chart-box.dropdown .legend-buttons-wrapper .legends-button {
    width: 100%;
    display: block;
    max-width: 150px;
    text-align: left;
    z-index: 11;
    position: absolute;
}

.secondary-legends span {
    position: absolute;
    margin-top: -22px;
    font-size: 12px;
    margin-left: -62px;
}

.secondary-legends .secondary-legends-button.checked,
.charts-wrapper .chart-box.dropdown .legend-buttons-wrapper .legends-button.checked {
    z-index: 12;
}

.secondary-legends .secondary-legends-button.active {
    background-color: #372d68;
    color: #ffffff;
}

.secondary-legends {
    width: 100%;
    text-align: center;
    height: 35px;
    margin: 10px 0;
}

.secondary-legends .secondary-legends-button {
    display: inline-block;
    padding: 5px 0px 5px 15px;
    font-size: 11px;
    float: none;
    background-color: #ffffff;
    border-radius: 3px;
    margin-left: 4px;
    position: absolute;
    margin-left: -60px;
    z-index: 100;
    color: #6d5bd0;
    min-height: 15px;
    cursor: pointer;
    text-indent: 10px;
    width: 100px;
    z-index: 10;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dropdowns-wrapper {
    margin-left: 47px;
    position: absolute;
    width: 600px;
    z-index: 10;
}

.dropdowns-wrapper .secondary-legends:hover {
    height: unset;
    z-index: 11;
}

.dropdowns-wrapper .secondary-legends:hover .secondary-legends-button {
    position: relative;
    display: block;
    margin-left: -10px;
    left: 15px;
}

.dropdowns-wrapper .secondary-legends:hover .secondary-legends-button.hidden {
    display: none;
}

.dropdowns-wrapper .secondary-legends .secondary-legends-button::before {
    content: '';
    display: block;
    width: 2px;
    height: 2px;
    border: 2px #cdcdcd solid;
    border-style: none solid solid none;
    padding: 2px;
    position: absolute;
    margin-left: -9px;
    margin-right: 0;
    margin-top: 1px;
    transform: rotate(45deg);
}

.dropdowns-wrapper .secondary-legends .secondary-legends-button.checked::before {
    background: none;
}

.dropdowns-wrapper .secondary-legends:hover .secondary-legends-button::before {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    background-color: #ffffff;
    border: 1px #ffffff solid;
    padding: 2px;
    position: absolute;
    margin-left: -11px;
    margin-right: 0;
    margin-top: 0px;
    text-indent: 15px;
    transform: rotate(0deg);
}

.dropdowns-wrapper .secondary-legends:hover .secondary-legends-button:hover {
    font-weight: bold;
}

.charts-wrapper .chart-box .legends-button {
    display: block;
    padding: 3px 5px 3px 20px;
    font-size: 11px;
    float: right;
    background-color: #cdcdcd;
    border-radius: 3px;
    margin-left: 4px;
    position: relative;
    z-index: 100;
    color: #ffffff;
    min-height: 15px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.dropdowns-wrapper .secondary-legends:hover .secondary-legends-button.checked::before,
.secondary-legends .secondary-legends-button.checked::before,
.charts-wrapper .chart-box .legends-button.checked::before {
    background-image: url(/src/Assets/Images/check.svg);
    background-size: 80%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.secondary-legends.dropdown .secondary-legends-button::before,
.charts-wrapper .chart-box.dropdown .legend-buttons-wrapper .legends-button::before {
    display: none;
}

.secondary-legends .secondary-legends-button::before,
.charts-wrapper .legends-button::before {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    background-color: #ffffff;
    border: 1px rgba(255, 255, 255, 0.7) solid;
    position: absolute;
    margin-left: -14px;
    margin-top: 3px;
}

.dropdowns-wrapper .secondary-legends {
    border: 1px #cdcdcd solid;
    border-radius: 10px;
    max-width: 130px;
    display: block;
    float: left;
    margin-right: 10px;
    background-color: #ffffff;
    height: 25px;
    padding-top: 3px;
}

.dropdowns-wrapper .secondary-legends.right {
    float: right;
    margin-right: 115px;
}

.dropdowns-wrapper .secondary-legends .checked {
    font-weight: bold;
}

.dropdowns-wrapper .secondary-legends:hover .hidden,
.dropdowns-wrapper .secondary-legends .hidden {
    display: none;
}

.charts-wrapper .info-boxes h4 {
    font-size: 15px;
}

.charts-wrapper .info-boxes-charts {
    float: left;
    width: calc(100% - 55px);
    margin-left: 55px;
}

.charts-wrapper .info-boxes-charts .chart-box {
    max-width: 595px;
    min-width: 545px;
    min-height: 480px;
    border: none;
    box-shadow: 0px 0px 10px lightgrey;
    border-radius: 10px;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 10px 0 5px 0;
    margin-right: 10px;
    width: calc(50% - 32px);
    background-color: #fbfbff;
}

.compare-overlay .render-as-switch,
.chart-box .render-as-switch {
    mask-image: url(/src/Assets/Images/column-chart.svg);
    background-color: silver;
    width: 17px;
    height: 15px;
    mask-repeat: no-repeat;
    float: right;
    margin-right: 20px;
}

.compare-overlay .render-as-switch {
    margin-right: 0;
    margin-top: -20px;
}

.compare-overlay .render-as-switch:hover,
.compare-overlay .render-as-switch.active,
.chart-box .render-as-switch:hover,
.chart-box .render-as-switch.active {
    background-color: #372d68;
    cursor: pointer;
}

.charts-wrapper .info-boxes-charts .chart-box .chart-data-missing {
    margin-top: 100px;
    width: 250px;
    margin-left: calc(50% - 208px);
}

.charts-wrapper .chart-box:nth-of-type(2) {
    margin-left: 0;
    margin-right: 0;
}

.content-wrapper .charts-wrapper.company-charts .chart-wrapper {
    min-width: unset;
    max-width: unset;
    height: 350px;
    margin-top: 20px;
    text-align: center;
    display: inline-block;
}

.content-wrapper .charts-wrapper .bar-chart-wrapper {
    width: calc(100% - 40px);
    text-align: center;
    display: inline-block;
    margin: 35px 0 0 calc(1% + 20px);
}

.content-wrapper .info-boxes .chart-box .status-neutral {
    font-size: 13px;
    padding: 3px 14px 4px 14px;
    border-radius: 6px;
    color: #6d5bd0;
    background-color: rgba(109, 91, 208, 0.1);
    background-repeat: no-repeat;
    background-position: 4px 9px;
    display: inline-block;
    margin-left: 80px;
}

.content-wrapper .top-buttons {
    margin-left: 80px;
}

.content-wrapper .reset-charts {
    color: #6d5bd0;
    font-size: 16px;
    line-height: 34px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    float: right;
    height: 36px;
    outline: none;
    margin: 20px 3px 20px 0;
    padding: 0 12px 0 12px;
    border: 1px transparent solid;
    border-radius: 4px;
    text-align: left;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
    z-index: 150;
    position: relative;
}

.content-wrapper .reset-charts:hover {
    border-color: #372d68;
    color: #372d68;
}

.content-wrapper .reset-charts .mask {
    background-color: #6d5bd0;
    mask-image: url(/src/Assets/Images/reset-charts.svg);
    mask-repeat: no-repeat;
    mask-size: 14px 14px;
    float: left;
    width: 14px;
    height: 14px;
    margin: 10px 6px 0 0;
}

.content-wrapper .reset-charts:hover .mask {
    background-color: #372d68;
}

.content-wrapper .chart-options {
    width: calc(100% - 600px);
    float: left;
}

.content-wrapper .chart-option {
    color: #6d5bd0;
    font-size: 15px;
    line-height: 32px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    float: left;
    height: 30px;
    outline: none;
    margin: 20px 3px 20px 0;
    padding: 0 11px 0 8px;
    border: 1px transparent solid;
    border-radius: 4px;
    text-align: left;
    letter-spacing: 0.1px;
    cursor: pointer;
    border: 1px transparent solid;
    border-radius: 4px;
    z-index: 150;
    position: relative;
    text-decoration: none;
}

.content-wrapper .chart-option:hover {
    color: #372d68;
    border-color: #372d68;
}

.content-wrapper .chart-option.current {
    background-color: #372d68;
    color: #ffffff;
    transition: all 0.15s ease-out;
}

.content-wrapper .reset-charts:hover {
    border-color: #372d68;
    color: #372d68;
}

.content-wrapper .charts-wrapper button:hover {
    border-color: #6d5bd0;
    transition: all 0.15s ease-out;
}

.content-wrapper iframe {
    width: calc(50% - 52px);
    height: 450px;
    overflow: hidden;
    margin: 5px 15px;
    padding: 10px 10px;
    background-color: #ffffff;
    border: 1px #e6e6e6 solid;
}

.content-wrapper .charts-wrapper .waterfall-chart-wrapper {
    width: calc(100% - 90px);
}

.content-wrapper .charts-wrapper .waterfall-chart-wrapper .chart-wrapper {
    width: 100%;
}

.content-wrapper .charts-wrapper .chart-drilldown span span {
    width: 100px;
    display: block;
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    padding: 8px 0;
    border-radius: 3px;
    top: 20px;
    right: 15px;
}

.spinner-wrapper {
    width: 100%;
    min-height: 100px;
    padding-top: 50px;
    text-align: center;
    float: left;
    margin-left: 0;
}

.spinner-wrapper .spinner {
    display: inline-block;
    width: 0px;
    height: 0px;
}
.spinner-wrapper .spinner:after {
    content: ' ';
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 0 0 -15px;
    border-radius: 50%;
    border: 2px solid #007cba;
    border-color: #007cba transparent #007cba transparent;
    animation: spinner 1.2s linear infinite;
}

.bar-chart-wrapper .spinner-wrapper {
    margin-top: 120px;
}

.license-choice-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.license-choice {
    background-color: #eeebff;
    box-sizing: border-box;
    margin-top: 15px;
    padding: 7px;
    width: 100%;
    max-width: 30em;
    border-radius: 4px;
    border: 1px transparent solid;
    color: #6d5bd0;
}

.license-choice:hover {
    cursor: pointer;
    border-color: #3a306e;
    color: #3a306e;
}

#settings-content {
    margin: 2em 2em;
    clear: both;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#settings-content > div {
    width: 100%;
    padding-right: 20px;
}

#settings-content h2 {
    font-size: 20px;
    position: relative;
}

#settings-content .date {
    white-space: nowrap;
}

#settings-content button {
    height: 2em;
    text-align: left;
    background-color: white;
    padding: 1px 7px 3px 7px;
    margin: 0.1em;
    color: #6d5bd0;
    cursor: pointer;
    border: 1px transparent solid;
}

#settings-content button.trash {
    color: #aa4154;
}

#settings-content button.trash:before {
    background-color: #da546c;
    color: #aa4154;
}

#settings-content .button:hover {
    border-color: #372d68;
    color: #372d68;
    transition: all 0.15s ease-out;
}

#settings-content .trash:hover {
    border-color: #aa4154;
    color: #aa4154;
}

#settings-content h2:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 300px;
    max-width: 85%;
    border-bottom: 1px solid #372d68;
}

.dropdown-input {
    margin-bottom: 20px;
    position: relative;
}

.dropdown-input span {
    top: 0;
    position: relative;
    font-size: 0.8em;
}

.dropdown-input .input-wrapper {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25px;
    margin-right: 10px;
    width: 130px;
    padding: 3px 6px;
    position: relative;
}

.dropdown-input .input-wrapper::before {
    content: '';
    width: 14px;
    height: 14px;
    margin-right: 15px;
    display: inline-block;
    position: absolute;
    left: 7px;
    top: 8px;
    background-color: #ffffff;
    background-image: url(/src/Assets/Images/chevron-down.svg);
    background-size: contain;
    opacity: 0.5;
    background-repeat: no-repeat;
}

.dropdown-input.enabled:hover .input-wrapper::before {
    background: none;
}

.dropdown-input.size-large .input-wrapper {
    width: 200px;
}

.dropdown-input.size-medium .input-wrapper {
    width: 165px;
}

.dropdown-input.enabled .input-wrapper {
    background-color: #fff;
    border: 1px solid #cdcdcd;
}

.dropdown-input .dropdown-input-button {
    background-color: #f9f9f9;
    color: #aaa;
    display: inline-block;
    font-size: 11px;
    min-height: 10px;
    line-height: 20px;
    overflow: hidden;
    padding: 0 0 1px 0;
    position: absolute;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 2px 0;
    width: calc(100% - 40px);
    z-index: 9;
}

.dropdown-input.enabled:hover .dropdown-input-button + .dropdown-input-button {
    padding: 5px 0 0 0;
}

.dropdown-input.enabled .dropdown-input-button {
    background-color: #fff;
    color: #6d5bd0;
    cursor: pointer;
}

.dropdown-input .dropdown-input-button.checked {
    z-index: 10;
    font-weight: bold;
}

.dropdown-input.enabled:hover .dropdown-input-button.checked {
    z-index: 13;
}

.dropdown-input.enabled:hover .dropdown-input-button.checked .check-mark {
    width: 12px;
    height: 12px;
    display: inline-block;
    position: absolute;
    left: 5px;
    top: 5px;
    background-image: url(/src/Assets/Images/check.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.dropdown-input.enabled .dropdown-input-button:hover {
    font-weight: bold;
}

.dropdown-input.enabled:hover .input-wrapper {
    height: unset;
    position: absolute;
    z-index: 11;
}

.dropdown-input.enabled:hover .dropdown-input-button {
    position: relative;
    z-index: 12;
}

dl {
    display: table;
    width: 100%;
    max-width: 40em;
}
dl > div {
    display: table-row;
}
dl > div > dt,
dl > div > dd {
    display: table-cell;
    border: none;
    padding: 0.25em;
}
dl > div > dt {
    font-weight: bold;
    width: 30%;
}

.alert {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.alert > div {
    font-size: 13px;
    margin: 2em 0 1em 20px;
    padding: 1em 2em;
    border-radius: 6px;
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: 4px 4px;
    max-width: 50em;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.alert.compact > div {
    margin: 1em 0 1em 0;
    padding: 0.5em 2em;
}
.alert.severity-error > div {
    color: #ba542c;
    background-color: rgba(218, 84, 108, 0.12);
}
.alert.severity-success > div {
    color: #83cf6c;
    background-color: rgba(33, 85, 36, 0.12);
}
.alert.severity-information > div {
    color: #bdb99b;
    background-color: rgba(50, 51, 38, 0.12);
}
.alert.severity-warning > div {
    color: #46371a;
    background-color: rgba(240, 230, 140, 0.4);
}
.alert span.message {
    margin-left: 1em;
}

/* *** GROUP OVERVIEW *** */

.group-overview-wrapper {
    margin-top: 1em;
    margin-left: 80px;
}
.group-overview-wrapper .column-based-table-wrapper {
    margin-left: 20px;
}

.group-overview-wrapper #controls {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
.group-overview-wrapper #controls #choice-select > span {
    text-transform: uppercase;
    font-size: 0.8em;
    color: #6d5bd0;
    display: block;
    margin: 2px 0 5px 0;
}

.group-overview-wrapper #controls .dropdown-input {
    margin: 0;
}
.group-overview-wrapper #company-select {
    min-height: 33px;
    font-size: 15px;
    line-height: 100%;
    display: flex;
    align-items: center;
    align-self: flex-end;
}
.group-overview-wrapper #company-select > div {
    width: 100%;
    text-align: center;
}
.group-overview-wrapper #company-select:hover {
    background-color: #372d68;
}

.company-picker .picker-menu li {
    cursor: pointer;
}

.company-picker .input-control {
    border: 1px solid #8b949e;
    border-radius: 5px;
    font-size: 16px;
    margin: 0 0 0.5em;
    padding: 8px 6px;
    width: calc(100% - 14px);
    display: flex;
}

.company-picker .input-wrapper {
    flex: 1 1 0%;
}

.company-picker .input-wrapper input {
    border: 0;
    width: calc(100% - 10px);
}

.company-picker .input-wrapper input:focus {
    outline: none;
}

.company-picker .input-expand {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
}

.company-picker .expand-separator {
    align-self: stretch;
    width: 1px;
    background-color: #f2f1fa;
    margin-bottom: 4px;
    margin-top: 4px;
}

.company-picker .input-expand-dropdown {
    display: block;
    mask-image: url(/src/Assets/Images/chevron-down.svg);
    background-color: #8b949e;
    mask-repeat: no-repeat;
    width: 24px;
    height: 15px;
    margin: -3px 0 0 10px;
    line-height: normal;
    position: relative;
    top: 3px;
}

.company-picker .input-expand-dropdown:hover {
    background-color: #000;
}

.company-picker .selector-wrapper {
    position: relative;
}

.company-picker ul.picker-menu {
    position: absolute;
    border: 1px solid #efefef;
    border-radius: 5px;
    list-style-type: none;
    margin: 0;
    padding: 4px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.175);
    max-height: 20em;
    overflow-y: scroll;
    background-color: #fff;
}

.company-picker ul.picker-menu li {
    line-height: 2.3em;
    padding: 4px;
}

.company-picker .highlighted {
    background-color: #eeebff;
}

.company-picker ul.selected {
    border: 1px solid #efefef;
    border-radius: 5px;
    list-style-type: none;
    margin: 0.5em 0;
    padding: 4px 0;
}

.company-picker ul.selected li {
    display: grid;
    padding: 0.5em 0;
    margin: 0 0.5em;
    grid-template-columns: 1fr auto;
}

.company-picker ul.selected li + li {
    border-top: 1px solid #efefef;
}

.company-picker button.trash {
    cursor: pointer;
    background-color: transparent;
}

.company-picker button.trash::before {
    background-color: #da546c;
}

.company-picker .warning {
    font-size: 13px;
    padding: 1px 6px 2px 16px;
    border-radius: 6px;
    color: #da546c;
    background-size: 10px 10px;
    background-color: rgba(218, 84, 108, 0.12);
    background-repeat: no-repeat;
    background-position: 4px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.company-picker .warning span {
    margin-left: 1em;
}

/* *** MONTH PICKER *** */

.month-picker {
    position: relative;
    z-index: 11;
}
.month-picker .invisible-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    margin: 0;
    padding: 0;
}
.month-picker .popup-container {
    position: relative;
    top: 2px;
}
.month-picker .picker-title {
    text-transform: uppercase;
    font-size: 0.8em;
    color: #6d5bd0;
}
.month-picker .picker-value {
    padding: 3px 35px 3px 6px;
    border-radius: 3px;
    border: 1px #6d5bd0 solid;
    line-height: 28px;
    width: 8em;
    font-size: 14px;
    outline-width: 0;
    text-indent: 5px;
    cursor: pointer;
}

.month-picker .popup {
    position: absolute;
    z-index: 100;
    top: 0;
    background: #fff;
    width: 260px;
    padding: 1em 1em;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.month-picker .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5em;
}

.month-picker .header label {
    padding: 0.5em 0;
}

.month-picker .header span {
    cursor: pointer;
    padding: 0.5em 1em;
    border-radius: 4px;
}

.month-picker .header span:hover {
    background-color: #eeebff;
}

.month-picker .header span.inactive {
    color: #aaa;
    cursor: default;
}

.month-picker .header span.inactive:hover {
    background-color: transparent;
}

.month-picker ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    padding: 0;
}

.month-picker li {
    display: grid;
    list-style-type: none;
    margin: 0;
    user-select: none;
    text-align: center;
    line-height: 2em;
    border-radius: 4px;
    cursor: pointer;
}

.month-picker li:hover {
    background-color: #eeebff;
}

.month-picker li.active {
    background-color: #372d68;
    color: #fff;
}

/* *** ...MONTH PICKER *** */

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (min-width: 2000px) {
    .row-based-table-filter-wrapper {
        margin-left: 1540px;
    }
}

@media screen and (max-width: 1440px) {
    .row-based-table-head {
        font-size: 14px;
    }
    .row-based-table-row {
        font-size: 13px;
        line-height: 22px;
    }
    .row-based-table-row .red,
    .row-based-table-row .status-ok,
    .row-based-table-row .status-neutral {
        background-position: 4px 4px;
        font-size: 13px;
    }
}

@media screen and (max-width: 1350px) {
    .company-info-visibility-toggle {
        float: right;
        margin: 33px 78px 0 0;
        position: relative;
    }

    .profit-and-loss-account-years-menu,
    .balance-sheets-account-years-menu,
    .content-wrapper .top-buttons {
        margin-left: 50px;
    }

    .column-based-table-wrapper {
        margin: 0 0 0 40px;
    }

    .group-overview-wrapper .column-based-table-wrapper {
        margin-left: 0;
    }

    .row-based-table-wrapper {
        padding: 0 0 0 50px;
    }

    .company-heading,
    .content-heading {
        padding: 0 0 0 50px;
    }

    .content-wrapper .charts-wrapper {
        width: 100%;
        margin: 5px 0;
        padding: 10px 0;
    }

    .charts-wrapper .info-boxes-charts {
        float: left;
        width: calc(100% - 15px);
        margin-left: 50px;
    }

    .charts-wrapper .info-boxes {
        margin-left: 50px;
        width: calc(100% - 69px);
    }
}

@media screen and (max-width: 1250px) {
    .top-bar-logout {
        margin-right: 18px;
    }

    .profit-and-loss-account-years-menu,
    .balance-sheets-account-years-menu,
    .content-wrapper .top-buttons {
        margin-left: 20px;
    }

    .company-info-visibility-toggle {
        margin-right: 19px;
    }

    .company-heading .help {
        margin-right: -11px;
    }

    .company-menu ul a {
        border-bottom: 4px solid transparent;
        border-radius: 6px 6px 0 0;
        color: #6d5bd0;
        height: 100%;
        letter-spacing: 0.2px;
        margin: 0 2px 0 0;
        min-width: 140px;
        padding: 14px 10px 12px;
        text-decoration: none;
        width: 100%;
    }

    .button.report-generator-opener {
        position: absolute;
        left: calc(100% - 131px);
    }

    .content-wrapper .charts-wrapper.company-charts .chart-wrapper {
        margin-top: 0px;
        margin-left: 35px;
    }

    .charts-wrapper .chart-box .legend-buttons-wrapper {
        float: left;
        margin-left: 43px;
        position: absolute;
    }

    .chart-box .dropdowns-wrapper {
        position: absolute;
        margin-left: calc(100% - 680px);
        margin-top: -30px;
    }

    .dropdowns-wrapper .secondary-legends {
        float: right;
        clear: right;
        margin-bottom: 20px;
        margin-right: 0 !important;
    }

    .charts-wrapper .chart-box.legends-as-legends .legend-buttons-wrapper {
        margin-top: 330px;
    }

    .charts-wrapper .info-boxes-charts {
        width: calc(100% - 20px);
        margin-left: 20px;
    }

    .charts-wrapper .info-boxes {
        margin-left: 20px;
        width: calc(100% - 37px);
    }

    .charts-wrapper .info-boxes-charts .chart-box {
        max-width: 1200px;
        min-width: 800px;
        width: calc(100% - 18px);
        min-height: 420px;
    }

    .charts-wrapper .chart-box:nth-of-type(2) {
        margin-top: 0;
    }

    .bottom-buttons-and-info .official-data-disclaimer {
        margin-left: 25px;
    }

    .bottom-buttons-and-info .help {
        float: right;
        margin-right: 21px;
        margin-top: -75px;
        position: relative;
    }

    .column-based-table-wrapper {
        margin: 0 0 0 30px;
    }

    .group-overview-wrapper .column-based-table-wrapper {
        margin-left: 0;
    }

    .content-heading {
        padding: 0 0 0 20px;
        width: calc(100% - 20px);
    }

    .row-based-table-wrapper-heading {
        margin: 15px 0 0 20px;
    }

    .row-based-table-wrapper {
        padding: 0;
    }

    .row-based-table-actions {
        margin: -5px 0 10px 20px;
    }

    .row-based-table-head {
        font-size: 14px;
    }

    .row-based-table-row {
        font-size: 13px;
        line-height: 22;
    }

    .row-based-table-row > div {
        padding: 8px 8px 4px 5px;
        min-height: 30px;
        line-height: 22px;
    }

    .row-based-table-row .red,
    .row-based-table-row .status-ok,
    .row-based-table-row .status-neutral {
        background-position: 4px 4px;
        font-size: 13px;
    }

    .row-based-table-row .status-neutral {
        background-image: url(/src/Assets/Images/status-neutral.svg);
        background-position: 4px 7px;
    }

    .row-based-table-head > div.small,
    .row-based-table-row div.small {
        max-width: 140px;
    }

    .row-based-table-head > div.medium,
    .row-based-table-row div.medium {
        max-width: 220px;
    }

    .content-wrapper .company-charts.charts-wrapper .bar-chart-wrapper,
    .content-wrapper .company-charts.charts-wrapper .waterfall-chart-wrapper {
        margin: 0;
    }

    .content-wrapper .charts-wrapper {
        margin: 5px 0px;
        padding: 10px 0px;
    }

    .row-based-table-filter-wrapper {
        margin-left: calc(100% - 380px);
    }
}

@media screen and (max-width: 650px) {
    #settings-content {
        display: block;
    }
}
